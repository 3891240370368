import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Reorder, useDragControls, useMotionValue, animate } from "framer-motion";
import * as Images from "../../images";
import SelectComponent from "../../form-action-utils/SelectComponent";
import { kirihaLabel } from "../Tab/planFlightRouteSetting";

const inactiveShadow = "0px 0px 0px rgba(0,0,0,0.8)";

const FlightMarkerKiriha = ({
  marker, key, position, chargingStation, 
  addMoreMarker, updateMarker, deleteMarker, 
  errorElement, errorMessage, 
  markerIdsOption,
  isDisableOptionKiriha
}) => {
  const { t } = useTranslation();
  const developerMode = Boolean(localStorage.getItem("developerMode") === "true");

  const handleInputChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    if (name === "id"  && value !== "kiriha") {
      value = parseInt(value);
    }
    if (name === 'angle') {
      if (!value) {
        value = '';
      }
      else {
        value = parseInt(value);
      }
    }
    let tempMarker = { ...marker, [name]: value };
    updateMarker(tempMarker, position);
    if (name === "action_type") {
      let tempMarker = { ...marker, action: { ...marker.action, "type": value } };
      updateMarker(tempMarker, position);
    }
    if (name === "action_hold_t") {
      let tempMarker = { ...marker, action: { ...marker.action, "hold_t": value } };
      updateMarker(tempMarker, position);
    }
    if (name === "action_rotate") {
      let tempMarker = { ...marker, action: { ...marker.action, "rotate": value } };
      updateMarker(tempMarker, position);
    }
  };
  
  const y = useMotionValue(0);
  const dragControls = useDragControls();
  const boxShadow = useMotionValue(inactiveShadow);
  
  useEffect(() => {
    let isActive = false;
    y.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, "5px 5px 10px rgba(0,0,0,0.3)");
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [y, boxShadow]);

  return (
    <Reorder.Item 
      key={key}
      value={marker} 
      className={'marker_item' + (marker.id === kirihaLabel ? " kiriha_marker" : "")}
      dragListener={false} 
      dragControls={dragControls} 
      style={{ y, boxShadow , background: '#fff', userSelect: 'none'}}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ duration: 0.25 }}
    >
      <div className="marker_item-number">
        <span onPointerDown={(event) => dragControls.start(event)} className="icon-drag">
          <Images.IconChangeMarker />
        </span>

        <div className="form-marker">
          <div className="form-marker-input">
            <Images.IconScan color="currentColor" />
            <div className="marker-ids">
              <SelectComponent 
                primaryIconSelect
                defaultNone
                name="id"
                data={markerIdsOption}
                value={marker.id}
                onChange={handleInputChange}
                disableVals={isDisableOptionKiriha || position === 0 ? ['kiriha'] : null}
              />
            </div>
          </div>
          {errorElement === position + ".id" &&
            <span className="mt-1 error-message">{errorMessage}</span>
          }
        </div>
      </div>
      
      <div className="marker_item-content">
        <div className="form-item form-marker-drone-height">
          <span>{t('plan.marker.drone.height')}</span>
          <label>
            <input
              className="dps-input-primary with-50percent"
              type="number"
              min={0}
              name="height"
              onChange={handleInputChange}
              value={marker.height}
            />
            {errorElement === position + ".height" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        <div className="form-item form-marker-drone-wall_distance">
          <span>{t('plan.marker.drone.wall_distance')}</span>
          <label>
            <input
              className="dps-input-primary with-50percent"
              type="number"
              min={0}
              name="wall_distance"
              onChange={handleInputChange}
              value={marker.wall_distance}
            />
            {errorElement === position + ".wall_distance" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        <div className="form-item form-marker-drone-landing_port">
          <span>{t('plan.marker.drone.landing_port')}</span>
          <label>
            <select
              value={marker.isStation}
              name="isStation"
              onChange={handleInputChange}
            >
              <option value="" disabled selected>
                {t("common.please_select")}
              </option>
              <option value="false">{t("common.no")}</option>
              {chargingStation.map((item, index) => {
                return (
                  <option key={index} value={item.stationId}>
                    {item.stationId}
                  </option>
                );
              })}
              <option value="else">{t("common.others")}</option>
            </select>
            <Images.IconArrowDown />
            {errorElement === position + ".station" && <span className="error-message d-inline">{errorMessage}</span>}
          </label>
        </div>

        {developerMode && 
          <div className="form-item form-marker-drone-mTemp">
            <span>{t('plan.marker.drone.mTemp')}</span>
            <label>
              <input
                disabled={!developerMode}
                className="dps-input-primary"
                type="text"
                name="mTemp1"
                onChange={handleInputChange}
                value={marker.mTemp1 || ""}
              />
              {errorElement === position + ".mTemp" && <span className="error-message d-inline">{errorMessage}</span>}
            </label>
          </div>
        }
      </div>

      <div className="marker_item-action">
        <button className="btn-remove_marker" onClick={deleteMarker}>－</button>
        <button className="btn-add_marker" onClick={() => { addMoreMarker(marker, position + 1) }}>＋</button>
      </div>
    </Reorder.Item>
  );
};

export default FlightMarkerKiriha;
