import React, {useEffect, useRef, useState} from 'react';
import {useDrop} from 'react-dnd';
import {MarkerDraggable} from './markerDraggable';
import update from 'immutability-helper';
import * as Constants from "../../utils/constants";
import MapSetScale from "./mapSetScale";
import {Modal} from "react-bootstrap";
import * as Images from "../images";
import {useTranslation} from "react-i18next";
import MapAnimation from "./mapAnimation";

const imageStyle = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  margin: '0 auto',
  display: 'block',
};

const containerAreaStyle = {
  display: 'block',
  width: '100%',
  height: '100%',
  flexShrink: 0,
  flexGrow: 1,
};

const containerStyle = {
  position: 'relative',
  display: 'contents',
  margin: '0 auto',
};

const mapCanvasStyle = {
  position: 'absolute',
  zIndex: 2,
  top: 0,
  left: 0
};

let border = '1px solid rgba(180, 184, 188, 0.5)';

/*
Only Move first marker.
After choose direction of first marker, it will recalculate all markers position.
 */
export const MapCanvas = (props) => {
  const {t, i18n} = useTranslation();
  let { showModal, markersModal, handleClose, openModal, handleEditChange, handleSave} = props


  let firstMarker = props.firstMarker;
  let scale = props.scale ? props.scale : {};
  let direction = props.direction ? props.direction : null;
  let flightMarkers = props.flightMarkers ? props.flightMarkers : [];

  // console.log(direction, props.action, props.markerInMap, 'callll log ajhsdjhavdjv');

  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(0);
  const [imageSource, setImageSource] = useState(null);

  const ref = useMapDrop(firstMarker, props);

  useEffect(() => {
    firstMarker = props.firstMarker;
    scale = props.scale ? props.scale : {};
    direction = props.direction ? props.direction : null;
    flightMarkers = props.flightMarkers ? props.flightMarkers : [];
  }, [props]);

  useEffect(() => {
    setLoading(false);
    imageStyle.width = '';
    imageStyle.height = '';
    containerStyle.width = '';
    containerStyle.height = '';
    containerStyle.display = 'contents';
  }, [props.url]);

  useEffect(() => {
    if (props.url && props.tab === 'map-advance') {
      setImageSource(props.url.includes('data:image') ? props.url : Constants.getResourceUrl() + props.url);
    }
  }, [props.url, props.tab]);

  useEffect(() => {
    let elem = document.getElementById('map-image-loaded' + props.id);
    let containerRef = document.getElementById(
        'image-map-view-area-id' + props.id);
    let zoom = 0;
    if (loading && elem && containerRef) {
      imageStyle.width = '';
      imageStyle.height = '';
      containerStyle.width = '';
      containerStyle.height = '';
      containerStyle.display = 'contents';
      let zoomX = containerRef.clientWidth / elem.width;
      let zoomY = containerRef.clientHeight / elem.height;

      zoom = Math.min(zoomX, zoomY);
      if (zoom !== 0) {
        containerStyle.height = elem.clientHeight * zoom;
        containerStyle.width = elem.clientWidth * zoom;
        containerStyle.display = 'block';
        imageStyle.height = elem.height * zoom;
        imageStyle.width = elem.width * zoom;
        mapCanvasStyle.height = imageStyle.height;
        mapCanvasStyle.width = imageStyle.width;

        zoom = imageStyle.height / elem.naturalHeight;

        setZoom(zoom);
        props.onZoomChange(zoom);
      }
    }
  }, [loading])

  const imageOnLoad = () => {
    setLoading(true);
  };

  const updateScale = (scaleObject) => {
    props.onScaleChange(scaleObject);
  };

  const renderEditModal = () => {
    return (
        <Modal centered show={showModal} onHide={handleClose}>

          <Modal.Header closeButton>
            <Modal.Title>{t(
                'planMap.edit_marker')} {markersModal[0].id}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="scroll-bar">

            {
              markersModal.map((marker, index) => {
                return (
                    <table key={index} className={index > 0 ? "mt-4" : ""}>
                      <tbody>
                      <tr>
                        <td>
                          <label>{t('plan.marker.drone.height')}</label>
                        </td>
                        <td>
                          <input type="number" min="0" name="height"
                                 onChange={(e) => handleEditChange(e, index)}
                                 value={marker.height}/>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>{t('plan.marker.drone.wall_distance')} </label>
                        </td>
                        <td>
                          <input type="number" min="0" name="wall_distance"
                                 onChange={(e) => handleEditChange(e, index)}
                                 value={marker.wall_distance}/>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>{t('plan.marker.drone.landing_port')} </label>
                        </td>
                        <td>
                          <select value={marker.isStation} name="isStation"
                                  onChange={(e) => handleEditChange(e, index)}>
                            <option value="true">{t('common.yes')}</option>
                            <option value="false">{t('common.no')}</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>{t('plan.marker.drone.direction')}</label>
                        </td>
                        <td>
                          <select value={marker.direction} name="direction"
                                  onChange={(e) => handleEditChange(e, index)}>
                            <option value="right">{t('common.right')}</option>
                            <option value="left">{t('common.left')}</option>
                            <option value="up">{t('common.up')}</option>
                            <option value="down">{t('common.down')}</option>
                            <option value="turn">{t(
                                'common.turn')}</option>
                            <option value="landed">{t(
                                'common.landing')}</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>{t(
                              'plan.marker.drone.marker_distance')} </label>
                        </td>
                        <td>
                          <input type="number" min="0" name="marker_distance"
                                 onChange={(e) => handleEditChange(e, index)}
                                 value={marker.marker_distance}/>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                )
              })
            }

            <button type="submit"
                    className="btn-save float-right position-relative"
                    onClick={handleSave}>
              <div className="fix-icon-center-ios">
                <Images.Icons name="IconSave"/>
              </div>
            </button>
          </Modal.Body>
        </Modal>
    );
  }

  return (
    <>
      {renderEditModal()}
      {
        props.url ?
          <div id={"image-map-view-area-id" + props.id} style={{...containerAreaStyle}}>
            {
              !loading ?
                <div className='loading'>
                  <img src={require('../../assets/images/loading.gif')} alt="loading"/>
                </div>
              : ''
            }
            <div style={{...containerStyle}} className={loading ? "image-map-view" : "invisible"}>
              <img 
                className="image-map-image" 
                src={imageSource}
                style={{...imageStyle, border}}
                onLoad={imageOnLoad} ref={ref}
                id={"map-image-loaded" + props.id}
                alt=""
              />
              { 
                ["setFirstMarker"].includes(props.action) ?
                  <MarkerDraggable 
                    key={-1}
                    index={0}
                    {...props.firstMarker}
                    zoom={zoom}
                    isSetFirstMarker={props.action === "setFirstMarker"}
                    direction={direction}
                    selectDirection={(d) => props.onDirectionChange(d)}
                    openModal={openModal}
                    canDoubleClick={true}
                    markerColor={null}
                    isChangeFirstMarker={props.isChangeFirstMarker}
                    handleClearSetingFirstMarker={props.handleClearSetingFirstMarker}
                  /> 
                : ''
              }
              { 
                props.action === "showRoute" ?
                  <MapAnimation 
                    style={{...mapCanvasStyle}}
                    markers={props.markerInMap}
                    planContent={flightMarkers}
                    direction={direction} 
                    scale={scale}
                    zoom={zoom}
                  /> 
                : ''
              }
              { 
                (["setFirstMarker"].includes(props.action)) 
                && props.markerInMap 
                && direction ?
                    props.markerInMap.map((marker, index) => {
                      return (
                        <MarkerDraggable 
                          key={index} 
                          index={index}
                          {...marker}
                          zoom={zoom}
                          action={props.action}
                          openModal={openModal}
                          markerColor={props.action === 'showRoute' ? "#00c7ff" : null}
                          isSetFirstMarker={props.action === "setFirstMarker"}
                          direction={direction}
                          selectDirection={(d) => props.onDirectionChange(d)}
                          canDoubleClick={"setFirstMarker" === props.action}
                          isChangeFirstMarker={props.isChangeFirstMarker}
                          handleClearSetingFirstMarker={props.handleClearSetingFirstMarker}
                        />
                      )}
                    ) 
                : ''
              }
              { 
                props.action === "setScale" ?
                  <MapSetScale 
                    style={{...mapCanvasStyle}}
                    updateScale={updateScale} scale={scale}
                    zoom={zoom}
                  /> 
                : ''
              } 
            </div>
          </div> 
        : 
        <p className="txt-red center notice"> {t('map.upload_image')}</p>
      }
    </>
  );
}

function useMapDrop(marker, props) {
  const ref = useRef();
  const [{canDrop, isOver}, dropTarget] = useDrop({
    accept: 'marker',

    drop(item, monitor) {
      let zoom = ref.current.height / ref.current.naturalHeight;
      
      if (item.top != undefined) {
        const delta = monitor.getDifferenceFromInitialOffset()
        let left = Math.round(item.left + delta.x / zoom);
        let top = Math.round(item.top + delta.y / zoom);
        if(!item.left && !item.top) {
          left += 45
          top += 45
        }
        moveBox(item, left, top);
      } else {
        const offset = monitor.getSourceClientOffset();
        if (offset && ref.current) {
          const dropTarget = ref.current.getBoundingClientRect();
          const left = (offset.x) - (dropTarget.left) + 20;
          const top = (offset.y) - (dropTarget.top) + 20;
          moveBox(item, left / zoom, top / zoom);
        }
      }
      border = '1px solid rgba(180, 184, 188, 0.5)';
      return undefined;
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const moveBox = (item, left, top) => {
    item['displayType'] = 'map';
    props.onFirstMarkerChange(update(marker, {
      $merge: {left, top}
    }));

  };

  const isActive = canDrop && isOver;

  if (isActive) {
    border = '1px dashed #fc405b';
  } else if (canDrop) {
    border = '1px dashed #fc405b';
  }

  return elem => {
    ref.current = elem;
    dropTarget(ref);
  };
}