import React, { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {isMobileOnly} from "react-device-detect";

import * as Images from "../../images";
import CheckBoxComponent from "../../form-action-utils/CheckBoxComponent";
import { POINT_CATEGORY } from "./mapPreviewStatusKirihaMode";

const ZoomFitComponent = (props) => {
  const {
    sizeMain, elementMainView, isFullScreenMode,
    viewPointCategory, setViewPointCategory,
    zoom,
    scale, updateScale,
    handleResize,
    containerWidth, containerHeight,
    imgWidth, imgHeight,
    children
  } = props;

  const [isMove, setIsMove] = useState(false);
  const [isFitWidth, setIsFitWidth] = useState(false);
  const [isFitHeight, setIsFitHeight] = useState(false);
  const [isShowSelectPointCategory, setIsShowSelectPointCategory] = useState(false);

  // resizeWindow
  const [size, setSize] = useState([0, 0]);

  const transformWrapperRef = useRef(null);
  const btnPointCategoryRef = useRef(null);

  useEffect(() => {
    function closeSelectPointCategory (e) {
      if (btnPointCategoryRef.current && !btnPointCategoryRef.current.contains(e.target) || e.target.matches(".btn-close-select")) {
        setIsShowSelectPointCategory(false);
      } 
    }

    document.body.addEventListener("click", closeSelectPointCategory);

    return () => document.body.removeEventListener("click", closeSelectPointCategory);
  }, [])

  useEffect(() => {
    centerImageView();
    handleResize();
  }, [useWindowSize(), zoom, sizeMain, elementMainView, isFullScreenMode])

  useEffect(() => {
    handleResize();
  }, [scale])

  useEffect(() => {
    setIsFitWidth(Math.floor(containerWidth) === Math.floor(imgWidth * scale));
    setIsFitHeight(Math.floor(containerHeight) === Math.floor(imgHeight * scale));
  }, [scale, zoom, sizeMain, useWindowSize()]) // check fit

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const handleZoom = (status) => {
    if (status) {
      transformWrapperRef.current.zoomIn();
    } else {
      transformWrapperRef.current.zoomOut();
    }

    setTimeout(() => {
      const { state } = transformWrapperRef.current.instance.getContext();
  
      updateScale(state.previousScale);
    }, 300);
  }

  const handleFitHeight = () => {
    setIsMove(true);
    setTimeout(() => {
      if (transformWrapperRef.current) {
        const { setTransform } = transformWrapperRef.current;
        const newScale = containerHeight / imgHeight;
        const transformX = (containerWidth - imgWidth) / 2;
        const transformY = (containerHeight - imgHeight) / 2;

        updateScale(newScale);
        setTransform(transformX, transformY, newScale);
      }
    }, 10);
  }

  const handleFitWidth = () => {
    setIsMove(true);
    setTimeout(() => {
      if (transformWrapperRef.current) {
        const { setTransform } = transformWrapperRef.current;
        const newScale = (containerWidth / imgWidth);
        const transformX = (containerWidth - imgWidth) / 2;
        const transformY = (containerHeight - imgHeight) / 2;
  
        updateScale(newScale);
        setTransform(transformX, transformY, newScale);
      }
    }, 10);
  }

  const centerImageView = () => {
    if (transformWrapperRef.current) {
      const { centerView } = transformWrapperRef.current;
      centerView();
    }
  }

  const handleViewPointCategory = (e) => {
    const { name, checked } = e.target;

    setViewPointCategory({
      ...viewPointCategory,
      [name]: checked
    })
  }

  const renderViewPointCategory = () => (
    <div className="view-point-category">
      {(isMobileOnly || elementMainView !== "map") && 
        <button className="btn-close-select">
          <Images.IconCloseSensors />
        </button>
      }
      <CheckBoxComponent 
        title="Onward" 
        name={POINT_CATEGORY.ONWARD}
        value={viewPointCategory[POINT_CATEGORY.ONWARD]} 
        onChange={handleViewPointCategory} 
        icon={<i className={"icon-point " + POINT_CATEGORY.ONWARD}></i>}
        className="check-point-view"
      />
      <CheckBoxComponent 
        title="Return" 
        name={POINT_CATEGORY.RETURN}
        value={viewPointCategory[POINT_CATEGORY.RETURN]} 
        onChange={handleViewPointCategory} 
        icon={<i className={"icon-point " + POINT_CATEGORY.RETURN}></i>}
        className="check-point-view"
      />
      <CheckBoxComponent 
        title="Kiriha" 
        name={POINT_CATEGORY.KIRIHA}
        value={viewPointCategory[POINT_CATEGORY.KIRIHA]} 
        onChange={handleViewPointCategory} 
        icon={<i className={"icon-point " + POINT_CATEGORY.KIRIHA}></i>}
        className="check-point-view"
      />
    </div>
  )
  
  return (
    <TransformWrapper
      ref={transformWrapperRef}
      disabled={!isMove}
      onWheel={(e) => updateScale(e.state.scale)}
      wrapperClass={"zoom-fit-wrapper"}
    >
      {({ zoomIn, zoomOut, setTransform, ...rest }) => (
        <React.Fragment>
          <div className='img-actions'>
            {(isMobileOnly || elementMainView !== "map") ?
              <button 
                className={"btn-point-category" + (isShowSelectPointCategory ? " active" : "")}
                onClick={() => setIsShowSelectPointCategory(true)}
                ref={btnPointCategoryRef}
              >
                <Images.IconDots />

                {renderViewPointCategory()}
              </button>
              :
              renderViewPointCategory()
            }
            <div className='action-img'>
              <button onClick={() => handleZoom(true)}><Images.IconPlus /></button>  
              <button onClick={() => handleZoom(false)}><Images.IconMinus /></button>
              <button className={isFitHeight ? "active" : undefined} onClick={handleFitHeight}>
                <Images.IconFitHeight color={isFitHeight ? "#fff" : undefined} />
              </button>
              <button className={isFitWidth ? "active" : undefined } onClick={handleFitWidth}>
                <Images.IconFitWidth color={isFitWidth ? "#fff" : undefined} />
              </button>
            </div>
            <button className={`btn-move ${isMove ? "active" : ""} `} onClick={() => setIsMove(!isMove)}>
              <Images.IconMove color={isMove ? "#fff" : undefined} />
            </button>
          </div>

          <TransformComponent wrapperStyle={{ width: "100%", height: '100%' }}>
            {children}
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  )
}

export default memo(ZoomFitComponent);