import React, {Component} from "react";
import MenuAdmin from "../../components/menu-admin";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from 'react-validation/build/button';
import * as Validator from "../../utils/validator";
import * as Constants from "../../utils/constants";
import {NotificationManager} from 'react-notifications';
import axios from "axios";
import LanguageSwitch from "../../components/languageSwitch";
import {withTranslation} from "react-i18next";
import MenuSettingAdmin from "../../components/menu/menu-setting-admin";

class Profile extends Component {
  constructor(props) {
    super(props);

    // Init component state
    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
      status: "",
    };

    this.getAdminInformation();
  }

  getHeader() {
    let headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Authorization': 'Bearer ' + JSON.parse(
          localStorage.getItem('user')).accessToken
    }
    return {headers: headers};
  }

  getAdminInformation() {
    axios.get(Constants.USER_ADMIN_URL, this.getHeader())
    .then(res => {
      this.setState(res.data);
      this.setState({password: ''});
    });
  }

  // Handle input change then set to state
  handleChange(field, e) {
    let updateState = this.state;
    updateState[field] = e.target.value;
    this.setState({updateState});
  }

  handleSave(t, e) {
    e.preventDefault();

    //validate input data
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      let request = Constants.USER_EDIT_URL + this.state.id;
      axios.put(request, this.state,
          {headers: this.getHeader().headers, params: {uuid: this.state.uuid}})
      .then(response => {
        NotificationManager.success(t('avatar.notifi_success'), t('common.success'),
            2000);
      }).catch(function (error) {
        NotificationManager.error(t('avatar.notifi_error3'), t('common.error'), 2000);
      });
    }
  }

  componentDidMount() {
  }

  //Render Ui for screen
  render() {
    const {t, i18n} = this.props;
    return (
        <>
          <div className="dps-menu ">
            <MenuAdmin currentRouter={this.props.location.pathname}/>
          </div>

          <div className="dps-content admin-page">
            <div className="dps-content-area">
              <div className="dps-title main-title">
                <h1>{t('avatar.main_title')}</h1>
                <LanguageSwitch/>
              </div>
              <div className="dps-main-content ">
                <div className="dps-main-content-area">
                  <MenuSettingAdmin currentRouter={this.props.location.pathname}></MenuSettingAdmin>
                  <div className="dps-tabs-content admin-setting">
                    <Form onSubmit={this.handleSave.bind(this, t)} ref={c => {
                      this.form = c
                    }} className="form-profile">

                      <div className="item">
                        <p>{t('login.email_placeholder')}</p>
                        <Input
                            type="text"
                            className="dps-input"
                            name="email"
                            placeholder={t('login.email_placeholder')}
                            value={this.state.email}
                            validations={[Validator.required, Validator.email]}
                            onChange={this.handleChange.bind(this, "email")}
                        />
                      </div>
                      <div className="item">
                        <p>{t('user.table.header_4')}</p>
                        <Input
                            type="password"
                            className="dps-input"
                            name="password"
                            placeholder={t('user.table.header_4')}
                            value={this.state.password}
                            validations={[Validator.required]}
                            onChange={this.handleChange.bind(this, "password")}
                        />
                      </div>
                      <CheckButton style={{display: 'none'}} ref={c => {
                        this.checkBtn = c
                      }}/>
                      <button className="dps-button">
                        <span>{t('common.update')}</span>
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

export default withTranslation("dps")(Profile);