import { memo } from "react";
import { useTranslation } from "react-i18next";

const menu = [
  {
    label: "setting.menu.profile",
    href: "/setting/profile",
    active: "profile"
  },
  {
    label: 'setting.menu.icon',
    href: "/setting/icon",
    active: "icon"
  },
  {
    label: 'setting.menu.parameter',
    href: "/setting/parameter",
    active: "parameter"
  },
  {
    label: 'setting.menu.droneType',
    href: "/setting/drone-type",
    active: "drone-type"
  },
  {
    label: 'setting.menu.stationType',
    href: "/setting/station-type",
    active: "station-type"
  },
  {
    label: 'setting.menu.privacySupports',
    href: "/setting/privacy-supports",
    active: "privacy-supports"
  }
]

const MenuSettingAdmin = (props) => {
  const { currentRouter } = props;
  const { t } = useTranslation();

  const renderMenuItem = (menu) => {
    return (
      menu.map(nav => (
        <a href={nav.href} className={currentRouter.includes(nav.active) ? "active" : ""} key={nav.active}>{t(nav.label)}</a>
      ))
    )
  }

  return (
    <div className="dps-tabs">
      {renderMenuItem(menu)}
    </div>
  )
}

export default memo(MenuSettingAdmin);