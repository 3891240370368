import React, {useState, useEffect, useRef, useLayoutEffect} from "react";
import {NotificationManager} from 'react-notifications';
import axios from "axios";
import {withTranslation} from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import BlotFormatter from "quill-blot-formatter";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MenuAdmin from "../../components/menu-admin";
import * as Constants from "../../utils/constants";
import LanguageSwitch from "../../components/languageSwitch";
import { getHeader } from "../../services/api.service";
import { timeConverter } from "../../utils/utils";
import MenuSettingAdmin from "../../components/menu/menu-setting-admin";

const toolbar = {
  container: [
    [{ 'header': 1 }, { 'header': 2 }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'color': [] }, { 'background': [] }],
    ['blockquote'],
    [{ 'direction': 'rtl' }],
    ['link', 'image'],
    ['clean'],
  ]
};

const CustomImageBlot = Quill.import("formats/image");

class ImageBlotWithCustomAttrs extends CustomImageBlot {
  static create(data) {
    let tempData;
    typeof data === 'object' ? tempData = data : tempData = { src: data };
    const node = super.create(tempData);
    Object.keys(tempData).forEach((key) => {
      node.setAttribute(key, tempData[key]);
    });
    return node;
  }

  static value(node) {
    const alt = node.getAttribute("alt");
    const src = node.getAttribute("src");
    const attrs = { alt, src };
    node.getAttributeNames().forEach((name) => {
      if (name !== "alt" && name !== "src") {
        attrs[name] = node.getAttribute(name);
      }
    });
    return attrs;
  }
}

Quill.register(ImageBlotWithCustomAttrs, true);
Quill.register("modules/blotFormatter", BlotFormatter);

const PrivacySupports = (props) => {
  const {t, i18n} = props;

  const [editorHtml, setEditorHtml] = useState("");
  const [UpdateAt, setUpdateAt] = useState(null);
  const [isEditChange, setIsEditChange] = useState(false);
  const [viewActive, setViewActive] = useState(); //support, terms-of-use, privacy

  useEffect(() => {
    setViewActive('support')
    getDataEditor('support')
  }, [])

  useEffect(() => {
    const overlay = document.querySelector('.blot-formatter__overlay');
    if(overlay) {
      overlay.style.display = 'none';
    }
  }, [viewActive])

  useEffect(() => {
    const heightHeaderEdit = document.querySelector('.header_edit_privacy-supports').offsetHeight + 6;
    const quill = document.querySelector('.quill')
    quill.style.maxHeight =  `calc(100% - ${heightHeaderEdit}px - 90px)`
  })

  const getDataEditor = (endPoint) => {
    axios.get(Constants.CONTENT_URL + endPoint, getHeader()).then((res) => {
      if (res.data.content) {
        setEditorHtml(res.data.content)
        setUpdateAt(Date.parse(res.data.updateAt) / 1000)
        setIsEditChange(false)
        setViewActive(endPoint)
      } else {
        setEditorHtml("")
        setUpdateAt(null)
        setIsEditChange(false)
        setViewActive(endPoint)
      }
    })
  }

  const handleChangeEditor = (value) => {
    setEditorHtml(value)
    setIsEditChange(true)
  }

  const handleSwicthPrivacySupport =  async (e) => {
    e.preventDefault();
    const {value} = e.target;

    if (isEditChange) {
      const result = await new Promise((resolve) => {
        confirmAlert({
          title: t("privacy_supports.confirm_alert"),
          message: "",
          buttons: [
            {
              label: t("common.ok"),
              onClick: () => {
                resolve({ shouldSave: true });
              },
            },
            {
              label: t("common.no"),
              onClick: () => {
                resolve({ shouldSave: false });
              }
            },
          ],
        });
      });

      if (result.shouldSave) {
        handleUpdate(value);
      } else{
        setViewActive(value)
        getDataEditor(value);
      }
    } else{
      setViewActive(value)
      getDataEditor(value);
    } 
    
    document.querySelector('.ql-container').scrollTo({ top: 0 })
  }

  const handleUpdate = (endPoint) => {
    axios.put(Constants.CONTENT_URL + viewActive, { content: editorHtml }, getHeader()).then(() => {
      getDataEditor(endPoint)
      NotificationManager.success(t("put.success"), "", 2000);
    }).catch(() => {
      NotificationManager.error(t("put.err"), "", 2000);
    })
  }

  //Render Ui for screen
  return (
      <>
        <div className="dps-menu ">
          <MenuAdmin currentRouter={props.location.pathname}/>
        </div>

        <div className="dps-content admin-page">
          <div className="dps-content-area">
            <div className="dps-title main-title">
              <h1>{t('avatar.main_title')}</h1>
              <LanguageSwitch/>
            </div>
            <div className="dps-main-content ">
              <div className="dps-main-content-area">
                <MenuSettingAdmin currentRouter={props.location.pathname}></MenuSettingAdmin>
                <div className="dps-tabs-content admin-privacy-supports">
                  <div className="header_edit_privacy-supports">
                    <div className="swicth_actions">
                      <label className="input-radio-container">
                        <input 
                          type="radio" 
                          name="swicth_privacy-supports" 
                          value="support"
                          checked = {viewActive === 'support'}
                          onChange={(e) => handleSwicthPrivacySupport(e)} 
                        />
                        <span className="checkmark"></span>
                        {t('privacy_supports.support')}
                      </label>
                      <label className="input-radio-container">
                        <input 
                          type="radio" 
                          name="swicth_privacy-supports" 
                          value="terms-of-use"
                          checked = {viewActive === 'terms-of-use'}
                          onChange={(e) => handleSwicthPrivacySupport(e)} 
                        />
                        <span className="checkmark"></span>
                        {t('privacy_supports.terms_of_service')}
                      </label>
                      <label className="input-radio-container">
                        <input 
                          type="radio" 
                          name="swicth_privacy-supports" 
                          value="privacy"
                          checked = {viewActive === 'privacy'}
                          onChange={(e) => handleSwicthPrivacySupport(e)} 
                        />
                        <span className="checkmark"></span>
                        {t('privacy_supports.privacy_policy')}
                      </label>
                    </div>
                    <button disabled={!isEditChange} onClick={() => handleUpdate(viewActive)}>{t('planMap.update')}</button>
                  </div>
                  {UpdateAt ? <p>{t('privacy_supports.last_update')} {timeConverter(UpdateAt, t)}</p> : undefined}

                  <ReactQuill
                    value={editorHtml} 
                    modules={{ blotFormatter: {
                      overlay: {
                        className: 'blot-formatter__overlay'
                      }
                    }, toolbar }}
                    onChange={handleChangeEditor}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default withTranslation("dps")(PrivacySupports);