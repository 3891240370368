import React, {useEffect, useState, useRef} from 'react'
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import * as Images from "../../images";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import * as Validator from "../../../utils/validator";
import {MapCanvas} from "../../map/mapCanvas";
import {NotificationManager} from "react-notifications";
import update from 'immutability-helper';
import {useTranslation} from "react-i18next";
import {TouchBackend} from 'react-dnd-touch-backend';
import {isTablet} from 'react-device-detect';
import cloneDeep from 'lodash/cloneDeep';

// Tab content Route Confirmation of Plan Tabs
// Usage: Can edit plan and verify planContent in map view

const initialMarkerState = [{
  "id": null,
  "top": 0,
  "left": 0,
  "hover_t": 0,
  "isStation": "false",
  "direction": "",
  "angle": 0,
  "marker_distance": 0,
  "wall_distance": 0,
  "height": 0,
  "action": {
    "type": "image",
    "rotate": 0,
    "hold_t": 0
  }
}];

const PlanMap = (props) => {
  const {t, i18n} = useTranslation();

  const {
    plan: {
      id,
      mapEditorImage
    },
    tab,
    mapEditorContent,
    updateMapEditorContent,
    handleChangeImageFile,
    flightMarkers,
    onChangeMapEditor
  } = props;
  const { scale, firstMarker, direction, markers, zoom } = mapEditorContent;

  let {handleChangeTab} = props

  const accountType = JSON.parse(localStorage.getItem('user')).accountType;

  const [action, setAction] = useState('upload'); // upload, setScale, setFirstMarker, showRoute

  // Tab Set First Marker
  const [showModal, setShowModal] = useState(false);
  const [markersModal, setMarkersModal] = useState(initialMarkerState);

  const refBtnUpload = useRef(null)
  const refBtnSetScale = useRef(null)
  const refBtnFirstMarker = useRef(null)
  const refBtnShowRoute = useRef(null)

  useEffect(() => {
    switch (action) {
      case 'upload':
        refBtnUpload.current.classList.add('active')
        refBtnUpload.current.classList.remove('pass')
        refBtnSetScale.current.classList.remove('active', 'pass')
        refBtnFirstMarker.current.classList.remove('active', 'pass')
        refBtnShowRoute.current.classList.remove('active', 'pass')
        return
      case 'setScale':
        refBtnUpload.current.classList.remove('active')
        refBtnUpload.current.classList.add('pass')
        refBtnSetScale.current.classList.add('active')
        refBtnSetScale.current.classList.remove('pass')
        refBtnFirstMarker.current.classList.remove('active', 'pass')
        refBtnShowRoute.current.classList.remove('active', 'pass')
        return
      case 'setFirstMarker':
        refBtnUpload.current.classList.remove('active')
        refBtnUpload.current.classList.add('pass')
        refBtnSetScale.current.classList.remove('active')
        refBtnSetScale.current.classList.add('pass')
        refBtnFirstMarker.current.classList.add('active')
        refBtnFirstMarker.current.classList.remove('pass')
        refBtnShowRoute.current.classList.remove('active', 'pass')
        return
      case 'showRoute':
        refBtnUpload.current.classList.remove('active')
        refBtnUpload.current.classList.add('pass')
        refBtnSetScale.current.classList.remove('active')
        refBtnSetScale.current.classList.add('pass')
        refBtnFirstMarker.current.classList.remove('active')
        refBtnFirstMarker.current.classList.add('pass')
        refBtnShowRoute.current.classList.add('active')
        return
    
      default: return
    }
  }, [action])

  useEffect(() => {
    setAction('upload')
  }, [tab])

  useEffect(() => {
    updateMapData();
  }, [scale, firstMarker, direction])

  const showScale = () => {
    if (mapEditorImage) {
      setAction('setScale');
    } else {
      NotificationManager.info(t('planMap.info.not_set_image'), '', 2000);
    }
  };

  const showFirstMarker = () => {
    if (flightMarkers.length === 0) {
      NotificationManager.info(t('planMap.info.set_first_marker_empty_plan'), '', 2000);
      return false;
    }
    if (!mapEditorImage) {
      NotificationManager.info(t('planMap.info.not_set_image'), '', 2000);
      return false;
    }
    if (!scale || !scale.value || !scale.startPoint || !scale.endPoint) {
      NotificationManager.info(t('planMap.info.not_set_scale'), '', 2000);
      return false;
    }
    setAction('setFirstMarker');
    updateMapEditorContent(update(mapEditorContent, { firstMarker: { id: { $set: flightMarkers[0].id } }}));
  };

  const showRoute = () => {
    if (flightMarkers.length === 0) {
      NotificationManager.info(t('planMap.info.set_first_marker_empty_plan'), '', 2000);
      return false;
    }
    if (!mapEditorImage) {
      NotificationManager.info(t('planMap.info.not_set_image'), '', 2000);
      return false;
    }
    if (!scale || !scale.value || !scale.startPoint || !scale.endPoint) {
      NotificationManager.info(t('planMap.info.not_set_scale'), '', 2000);
      return false;
    }
    if (!direction) {
      NotificationManager.info(t('planMap.info.not_set_direction'), '', 2000);
      return false;
    }
    setAction('showRoute');
  };

  const uploadImageMapEditor = () => {
    setAction('upload');
  };

  // Tab Upload
  const handleClickChangeFile = () => {
    document.getElementById('imageMapEditorUpload').click()
  }

  const handleZoomChange = (newZoom) => {
    updateMapEditorContent(update(mapEditorContent, { zoom: { $set: newZoom }}));
  }

  // Tab Set Scale
  const handleChange = (event) => {
    const {value} = event.target;
    updateMapEditorContent(update(mapEditorContent, { scale: { value: { $set: value } } }));
    onChangeMapEditor();
  };

  const handleScaleChange = (scalePosition) => {
    updateMapEditorContent(update(mapEditorContent, { scale: { 
      startPoint: { $set: scalePosition.startPoint },
      endPoint: { $set: scalePosition.endPoint },
    }}));
    onChangeMapEditor();
  }

  // Tab Set First Marker
  const handleFirstMarkerChange = (firstMarkerInfo) => {
    updateMapEditorContent(update(mapEditorContent, { firstMarker: { $set: firstMarkerInfo }}));
    onChangeMapEditor();
  }

  const handleDirectionChange = (direction) => {
    updateMapEditorContent(update(mapEditorContent, { direction: { $set: direction }}));
    onChangeMapEditor();
  }

  const updateMapData = () => {
    if (direction && scale) {
      let updatedMarkerInMap = [];
      let realMapDirection = direction;
      let latestMoveDirection;

      let latestMarker = firstMarker;

      updatedMarkerInMap.push(firstMarker);
      for (let i = 0; i < flightMarkers.length; i++) {
        const info = flightMarkers[i];
        let marker = {
          id: flightMarkers[i + 1] ? flightMarkers[i + 1].id : null,
        };
        if (i === 0) {
          latestMoveDirection = info.direction;
        }

        if (["left", "right"].includes(info.direction)) {
          // Move
          const distanceInMap = getDistanceInMap(info.marker_distance);
          realMapDirection = getRealMapDirection(
            realMapDirection,
            info.direction,
            latestMoveDirection
          );

          // Move by marker_distance
          marker.top =
            latestMarker.top +
            (realMapDirection === "up" 
              ? -1
              : realMapDirection === "down" 
              ? 1 
              : 0
            ) * distanceInMap;
          marker.left = latestMarker.left +
            (realMapDirection === "left"
              ? -1
              : realMapDirection === "right"
              ? 1
              : 0) *
              distanceInMap;
          latestMoveDirection = info.direction;
        }
        // else if (info.direction === "turn" && (Number(info.action.rotate) === 90 || Number(info.action.rotate) === -90))
        // ADDNEW
        else if (info.direction === "turn" && (Number(info.angle) === 90 || Number(info.angle) === -90)) {
          // Rotate:
          const nextWallDistance = flightMarkers[i + 1]
            ? flightMarkers[i + 1].wall_distance
            : 0;
          const markerMoveResult = moveMarker(
            latestMarker,
            realMapDirection,
            latestMoveDirection,
            nextWallDistance,
            info
          );
          marker.top = markerMoveResult.top;
          marker.left = markerMoveResult.left;
          // const arr = ("turn" === info.direction && info.action.rotate == 90) ? ["up", "right", "down", "left"] : ["up", "left", "down", "right"];
          // const arr = (Number(info.action.rotate) === 90) ? ["up", "right", "down", "left"] : ["up", "left", "down", "right"];
          // ADDNEW
          const arr =
            Number(info.angle) === 90
              ? ["up", "right", "down", "left"]
              : ["up", "left", "down", "right"];
          const indexOfCurrentDirection = arr.findIndex(
            (item) => item === realMapDirection
          );
          realMapDirection =
            indexOfCurrentDirection === 3
              ? arr[0]
              : arr[indexOfCurrentDirection + 1];
        } else if ("landed" === info.direction) {
          // Stop
          break;
        }

        latestMarker = marker;
        updatedMarkerInMap.push(marker);
      }

      updateMapEditorContent(update(mapEditorContent, { markers: { $set: updatedMarkerInMap }}));
    }
  };

  const getDistanceInMap = (distance) => {
    let { startPoint, endPoint, value } = scale;
    distance = parseFloat(distance);
    value = parseFloat(value);
    if (!(startPoint && endPoint)) {
      startPoint = {
        top: 0,
        left: 0,
      };
      endPoint = {
        top: 0,
        left: 0,
      };
    }

    const distanceOnMap = Math.sqrt(
      Math.pow(startPoint.top - endPoint.top, 2) +
      Math.pow(startPoint.left - endPoint.left, 2)
    );
    return (distanceOnMap / zoom) * (distance / value);
  };

  const getRealMapDirection = (
    realMapDirection,
    planMarkerDirection,
    latestMoveDirection
  ) => {
    if (planMarkerDirection === latestMoveDirection) {
      return realMapDirection;
    } else {
      const arr = ["up", "right", "down", "left"];
      const arrOpposite = ["down", "left", "up", "right"];
      const index = arr.findIndex((item) => item === realMapDirection);
      return arrOpposite[index];
    }
  };

  const moveMarker = (
    latestMarker,
    realMapDirection,
    latestMoveDirection,
    nextWallDistance,
    info
  ) => {
    nextWallDistance = parseFloat(nextWallDistance);
    const currentMoveDirection = info.direction;
    // const currentRotate = info.action.rotate;
    // ADDNEW
    const currentRotate = info.angle;
    const currentWallDistance = parseFloat(info.wall_distance);

    const currentWallDistanceInMap = getDistanceInMap(currentWallDistance);
    const nextWallDistanceInMap = getDistanceInMap(nextWallDistance);
    //const defaultDistance = DEFAULT_DISTANCE_TO_MARKER / zoom;
    if (realMapDirection === "up") {
      if (latestMoveDirection === "right") {
        return {
          top:
            latestMarker.top +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? -1
              : 1) *
              nextWallDistanceInMap,
          left: latestMarker.left + currentWallDistanceInMap,
        };
      } else {
        return {
          top:
            latestMarker.top +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? 1
              : -1) *
              nextWallDistanceInMap,
          left: latestMarker.left - currentWallDistanceInMap,
        };
      }
    } else if (realMapDirection === "right") {
      if (latestMoveDirection === "right") {
        return {
          top: latestMarker.top + currentWallDistanceInMap,
          left:
            latestMarker.left +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? 1
              : -1) *
              nextWallDistanceInMap,
        };
      } else {
        return {
          top: latestMarker.top - currentWallDistanceInMap,
          left:
            latestMarker.left +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? -1
              : 1) *
              nextWallDistanceInMap,
        };
      }
    } else if (realMapDirection === "down") {
      if (latestMoveDirection === "right") {
        return {
          top:
            latestMarker.top +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? 1
              : -1) *
              nextWallDistanceInMap,
          left: latestMarker.left - currentWallDistanceInMap,
        };
      } else {
        return {
          top:
            latestMarker.top +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? -1
              : 1) *
              nextWallDistanceInMap,
          left: latestMarker.left + currentWallDistanceInMap,
        };
      }
    } else if (realMapDirection === "left") {
      if (latestMoveDirection === "right") {
        return {
          top: latestMarker.top - currentWallDistanceInMap,
          left:
            latestMarker.left +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? -1
              : 1) *
              nextWallDistanceInMap,
        };
      } else {
        return {
          top: latestMarker.top + currentWallDistanceInMap,
          left:
            latestMarker.left +
            (currentMoveDirection === "turn" && Number(currentRotate) === 90
              ? 1
              : -1) *
              nextWallDistanceInMap,
        };
      }
    }
  };

  const openModal = (markerId) => {
    var markers = flightMarkers.filter(
        (item) => parseInt(item.id) === parseInt(markerId));
    if (markers && markers.length > 0) {
      setMarkersModal(markers);
      setShowModal(true)
    }
  };

  const handleClose = () => {
    setShowModal(false)
  };

  const handleEditChange = (event, index) => {
    const {name, value} = event.target;
    setMarkersModal(update(markersModal, {[index]: {$merge: {[name]: value}}}));
  };

  const handleSave = () => {
    let indexArr = [];
    const markerId = markersModal[0].id;
    let flight = cloneDeep(flightMarkers);
    for (let i = 0; i < flight.length; i++) {
      if (flight[i].id === markerId) {
        indexArr.push(i);
      }
    }
    markersModal.map((marker, i) => {
      let updateIndex = indexArr[i];
      flight = update(flight, {[updateIndex]: {$set: marker}})
    });
    props.handleUpdatePlanContent(flight);
    setShowModal(false);
  };

  const switchContentTabEditAdvance = () => {
    switch (action) {
      case 'upload':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_2.upload.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_2.upload.desc_1')} </span>
              <span>{t('plan.tab_2.upload.desc_2')}</span>
            </p>
            <div className="tab-from" onClick={() => handleClickChangeFile()}>
              <label>{t('plan.tab_2.upload.label')}</label>
              <div className="mask-tab-form">
                <Form onSubmit={props.handleSaveMap} className="d-inline-block hidden">
                  <Input
                    id="imageMapEditorUpload"
                    type="file"
                    name="planName"
                    placeholder={accountType === 'robot' ? t('planMap.travel_plan_name') : t('planMap.flight_plan_name')}
                    message={accountType === 'robot' ? t('planMap.Please_enter_the_move_plane_name') : t('planMap.Please_enter_the_flight_plane_name')}
                    accept="image/*,application/pdf"
                    validations={[Validator.required]}
                    onChange={(e) => handleChangeImageFile(e, tab)}
                  />
                </Form>
                <span>{t('plan.tab_2.upload.selected')}</span>
              </div>
            </div>

            <div className="tab-actions">
              <button className='next-tab' onClick={showScale}>{t('plan.btn_next_tab')}</button>
            </div>
          </>
        )
      case 'setScale':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_2.set_scale.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_2.set_scale.desc_1')} </span>
              <span>{t('plan.tab_2.set_scale.desc_2')}</span>
            </p>
            <div className="tab-from">
              <label>{t('plan.tab_2.set_scale.label')}</label>
              <div className='mask-input'>
                <input 
                  type="number" 
                  defaultValue={0} 
                  value={scale?.value}
                  min={0} 
                  onChange={handleChange}
                />
                <div className='custom-value'>
                  <span>{scale?.value || 0}</span>
                  <span> m</span>
                </div>
              </div>
            </div>

            <div className="tab-actions">
              <button className='pre-tab' onClick={uploadImageMapEditor}>{t('plan.btn_pre_tab')}</button>
              <button className='next-tab' onClick={showFirstMarker}>{t('plan.btn_next_tab')}</button>
            </div>
          </>
        )
      case 'setFirstMarker':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_2.set_first_marker.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_2.set_first_marker.desc_1')} </span>
              <span>{t('plan.tab_2.set_first_marker.desc_2')}</span>
            </p>
            <div className="tab-actions">
              <button className='pre-tab' onClick={showScale}>{t('plan.btn_pre_tab')}</button>
              <button className='next-tab' onClick={showRoute}>{t('plan.btn_next_tab')}</button>
            </div>
          </>
        )
      case 'showRoute':
        return(
          <>
            <h3 className='tab-title'>{t('plan.tab_2.set_show_route.title')}</h3>
            <p className='tab-desc'>
              <span>{t('plan.tab_2.set_show_route.desc_1')}</span>
              <br /> <br />
              <span>{t('plan.tab_2.set_show_route.desc_2')}</span>
              <span>
                <button 
                  style={{
                    display: 'inline-block', 
                    fontSize: 'inherit', 
                    border: 0, 
                    background: 'transparent', 
                    padding: 0, 
                    textDecoration: 'underline', 
                    marginLeft: '2px'
                  }}
                  onClick={() => handleChangeTab('view')}
                >{t('plan.tab_2.set_show_route.btn_go_to_tab_update')}</button>
              </span>
            </p>
          </>
        )
      default: return
    }
  }

  return (
    <DndProvider backend={isTablet ? TouchBackend : HTML5Backend}>
      <div className="map-editor">
        <div className="menu-zone">
          <div className="action">
            <button ref={refBtnUpload} className='dps-button_tab' onClick={uploadImageMapEditor}>{t('plan.tab_2.upload.text_btn')}</button>
            <Images.IconArrowTab color='currentColor' />
            <button ref={refBtnSetScale} className="dps-button_tab" onClick={showScale}>{t('plan.tab_2.set_scale.text_btn')}</button>
            <Images.IconArrowTab color='currentColor' />
            <button ref={refBtnFirstMarker} className="dps-button_tab" onClick={showFirstMarker}>{t('plan.tab_2.set_first_marker.text_btn')}</button>
            <Images.IconArrowTab color='currentColor' />
            <button ref={refBtnShowRoute} className="dps-button_tab" onClick={showRoute}>{t('plan.tab_2.set_show_route.text_btn')}</button>
          </div>
        </div>
        <div className="map-zone">
          <div className="content-map-zone">
            {switchContentTabEditAdvance()}
          </div>
          <div className="preview-map-zone">
            <MapCanvas
              url={mapEditorImage}
              tab={tab}
              action={action}
              onScaleChange={handleScaleChange}
              onDirectionChange={handleDirectionChange}
              onFirstMarkerChange={handleFirstMarkerChange}
              handleClearSetingFirstMarker={props.handleClearSetingFirstMarker}
              onZoomChange={handleZoomChange}
              showModal={showModal}
              markersModal={markersModal}
              handleClose={handleClose}
              openModal={openModal}
              handleEditChange={handleEditChange}
              handleSave={handleSave}

              id={id}
              flightMarkers={flightMarkers}
              direction={direction}
              firstMarker={firstMarker}
              isChangeFirstMarker={props.isChangeFirstMarker}
              markerInMap={markers}
              scale={scale}
              zoom={zoom}
            />
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default PlanMap;