import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import MenuAdmin from "../../components/menu-admin";
import {confirmAlert} from 'react-confirm-alert';
import * as Images from "../../components/images";
import Form from "react-validation/build/form";
import * as Constants from "../../utils/constants";
import {doDelete, doGet} from "../../services/api.service";
import {useTranslation} from 'react-i18next';
import LanguageSwitch from "../../components/languageSwitch";
import {NotificationManager} from 'react-notifications';
import pdfjs from "pdfjs-dist/legacy/build/pdf.js"
import axios from "axios";
import MenuSettingAdmin from "../../components/menu/menu-setting-admin";

const tableHeader = [
  'setting.droneType.table.header_1',
  'setting.droneType.table.header_2',
  'setting.droneType.table.header_6',
  ''
];

const initialStationType = {
  name: "",
  imageName: "",
};

export default function StationType(props) {
  const { t } = useTranslation();

  const [stationTypes, setStationTypes] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stationType, setStationType] = useState(initialStationType);
  const [stationTypeImageFile, setStationTypeImageFile] = useState(null);
  const [canSaveInEdit, setCanSaveInEdit] = useState(false);

  useEffect(() => {
    getListDroneTypes();
  }, [])

  // get data
  const getListDroneTypes = () => {
    doGet(Constants.STATION_TYPE_URL, null,
      (res) => {
        setStationTypes(res.data);
      },
      (error) => console.log(error)
    )
  }

  // action
  const onHide = () => {
    setIsEdit(false);
    setIsShowModal(false);
    setCanSaveInEdit(false);
    setStationTypeImageFile(null);
    setStationType(initialStationType);
  }

  const handleEditStationType = (stationType) => {
    setIsShowModal(true);
    setIsEdit(true);
    setStationType(stationType);
  }

  // handle change form
  const handleChaneStationType = (event) => {
    const { value } = event.target;
    setStationType({
      ...stationType,
      name: value
    })
    setCanSaveInEdit(true);
  }

  const changeStationTypeImgEdit = (e) => {
    e.preventDefault();
    document.getElementById('stationTypeImgEdit').click()
  }

  const handleChangeImageFile = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].type !== "application/pdf") {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        setStationType({
          ...stationType,
          imageName: e.target.files[0].name,
        })
        setStationTypeImageFile(e.target.files[0]);
        setCanSaveInEdit(true);
      } else {
        let file = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function () {
          let pdfData = new Uint8Array(this.result);
          // Using DocumentInitParameters object to load binary data.
          let loadingTask = pdfjs.getDocument({ data: pdfData });
          loadingTask.promise.then(
            function (pdf) {
              console.log("PDF loaded");

              // Fetch the first page
              let pageNumber = 1;
              pdf.getPage(pageNumber).then(function (page) {
                console.log("Page loaded");

                let scale = 1.0;
                let viewport = page.getViewport({ scale: scale });

                // Prepare canvas using PDF page dimensions
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                let renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                let renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                  let url = canvas.toDataURL();
                  // Split the base64 string in data and contentType
                  let block = url.split(";");
                  // Get the content type
                  let contentType = block[0].split(":")[1]; // In this case "image/gif"
                  // get the real base64 content of the file
                  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

                  // Convert to blob
                  let blob = b64toBlob(realData, contentType);
                  setStationType({
                    ...stationType,
                    imageName: file.name,
                  })
                  setStationTypeImageFile(blob);
                  setCanSaveInEdit(true);
                });
              });
            },
            function (reason) {
              // PDF loading error
              console.error(reason);
            }
          );
        };
        fileReader.readAsArrayBuffer(file);
      }
    }
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // handle submit data
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setCanSaveInEdit(false);

    const url = isEdit ? `${Constants.STATION_TYPE_URL}/${stationType.id}` : Constants.STATION_TYPE_URL;
    const method = isEdit ? "put" : "post";

    const formData = new FormData();
    formData.append('name', stationType.name);
    if (stationTypeImageFile) {
      formData.append('file', stationTypeImageFile);
    }

    axios[method](url, formData).then(res => {
      NotificationManager.success("Saved", '', 2000);
      onHide();
      getListDroneTypes();
    }).catch((err) => {
      const { err_msg } = err.response.data;
      NotificationManager.error(err_msg, '', 2000);
      setCanSaveInEdit(true);
    })
  }

  const handleDeleteStationType = (event, id) => {
    event.preventDefault();
    confirmAlert({
      title: t("setting.do_you_want_delete_it"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            const url = Constants.STATION_TYPE_URL + "/" + id;
            doDelete(url, () => {
              NotificationManager.success("Deleted", "", 2000);
              onHide();
              getListDroneTypes();
            }, () => {
              NotificationManager.error(t("dialog_delete.confirm_error"), "", 2000);
            })
          },
        },
        {
          label: t("common.back"),
        },
      ],
    });
  }

  // render table
  function renderItemHeaderTable(data, t) {
    return data.map((item, index) => {
      if (!item) return <div className="clmn" style={{ flex: 'unset', flexBasis: "100px" }} key={index}></div>;
      return <div className="clmn" key={index}>{t(item)}</div>;
    });
  }

  function renderItemTable(data) {
    return data.map((item, index) => {
      return (
          <div className="list-item" key={index}>
            <div className="clmn">
              {index + 1}
            </div>
            <div className="clmn">
              {item.name}
            </div>
            <div className="drone_img clmn">
              {item.imagePath &&
                <img src={Constants.getResourceUrl() + item.imagePath} alt="" />
              }
            </div>

            <div className="actions d-flex clmn" style={{ flex: "unset", flexBasis: "100px" }}>
              <button className="btn-small edit position-relative" onClick={() => handleEditStationType(item)}>
                <div className="fix-icon-center-ios">
                  <Images.Icons name="IconPen"/>
                </div>
              </button>
              <button className="btn-small edit position-relative" onClick={(e) => handleDeleteStationType(e, item.id)}>
                <div className="fix-icon-center-ios">
                  <Images.Icons name="IconTrash"/>
                </div>
              </button>
            </div>
          </div>
      );
    });
  }
 
  return (
    <>
      <div className="dps-menu ">
        <MenuAdmin currentRouter={props.location.pathname}/>
      </div>
      <div className="dps-content admin-page setting-page">
        <div className="dps-content-area">
          <div className="dps-title main-title">
            <h1>{t('avatar.main_title')}</h1>
            <LanguageSwitch/>
          </div>
          <div className="dps-main-content">
            <div className="dps-main-content-area">
              <MenuSettingAdmin currentRouter={props.location.pathname}></MenuSettingAdmin>
              <div className="dps-tabs-content admin-setting d-flex flex-column position-relative bg-light">
                <button className="add-new" onClick={() => setIsShowModal(true)}>+ New Station Type</button>
                <div className="dps-list-f table-setting">
                  <div className="header">
                    {renderItemHeaderTable(tableHeader, t)}
                  </div>
                  <div className="body">
                    {renderItemTable(stationTypes)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={isShowModal}
        onHide={onHide}
        className="edit-form setting-form"
        dialogClassName="setting-form-dialog"
        centered
      >
        <Modal.Body>
          <button className="btn-close" onClick={onHide}></button>
          <Form className="edit-type" onSubmit={handleSubmit}>
            <div className="item">
              <label>{t('setting.droneType.table.header_2')}</label>
              <input 
                className="grow-1"
                type="text"
                value={stationType.name}
                name="name"
                onChange={handleChaneStationType}
              />
            </div>
            <div className="item">
              <label>{t('setting.droneType.table.header_6')}</label>
              <input
                id="stationTypeImgEdit"
                type="file"
                className="hidden"
                name="file"
                value={stationType.file}
                onChange={handleChangeImageFile}
              />
              <div className="mash_input grow-1">
                {stationType.imageName ? stationType.imageName : t('setting.droneType.file_select')}
              </div>
              <button type="button" className="icon_folder" onClick={(e) => changeStationTypeImgEdit(e)}><Images.IconFolder /></button>
            </div>
            <div className="actions">
              {isEdit &&
                <button 
                  className="btn-delete"
                  onClick={(e) => handleDeleteStationType(e, stationType.id)}
                >
                  <Images.IconTrash />
                </button>
              }
              <button 
                type="submit" 
                className="btn-save"
                disabled={!canSaveInEdit}
              >
                OK
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}