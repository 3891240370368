import { memo } from "react";
import { IconSelect, IconArrowDown } from "../images";

const SelectComponent = (props) => {
  const { name, data, onChange, defaultChecked, value, className, defaultNone, primaryIconSelect, disableVals } = props;

  return (
    <div className="dps-select">
      <select
        className={className}
        name={name}
        defaultValue={defaultChecked}
        value={value}
        onChange={onChange}
      >
        {defaultNone &&
          <option style={{display: "none"}}></option>
        }
        {data.map(item => (
          <option value={item.value} key={item.value + Math.random()} disabled={disableVals?.includes(item.value)}>{item.title}</option>
        ))}
      </select>
      {primaryIconSelect ? <IconArrowDown /> : <IconSelect color="currentColor" />}
    </div>
  )
}

export default memo(SelectComponent);