import React, {useEffect, useState, createContext, useMemo} from 'react'
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import axios from 'axios';
import { cloneDeep } from 'lodash';
import * as Validator from "../../../utils/validator";
import update from 'immutability-helper';
import {useTranslation} from "react-i18next";
import {TouchBackend} from 'react-dnd-touch-backend';
import {isTablet} from 'react-device-detect';
import { MapCanvasKirihaMode } from '../../map/KirihaMode/mapCanvaKirihaMode';
import * as Constants from "../../../utils/constants";
import { getHeader } from "../../../services/api.service";

import * as Images from "../../images";
import { calculateAngle } from '../../../utils/kiriha';

export const MapContext = createContext();

const initViewOption = {
  origin: true,
  calibration: false,
  axis: false
}

const PlanMapKirihaMode = (props) => {
  const {t} = useTranslation();
  const accountType = JSON.parse(localStorage.getItem('user')).accountType;

  const { 
    tab, plan, planRouterSetting, chargingStation,
    mapEditorContentKiriha, handleUpdateMapEditorContentKiriha,
    handleChangeImageFile,
    markerKirihaData,
    updateMarkerKirihaData,
    onChangeRouterSt
  } = props;

  const { mapEditorImage } = plan;

  const [viewOption, setViewOption] = useState(initViewOption);
  const [markerDataCsv, setMarkerDataCsv] = useState([]);
  const [stationIdsSelected, setStationIdsSelected] = useState([]);
  const [chargingStationsView, setChargingStationsView] = useState([]);
  const [stationTypes, setStationTypes] = useState([]);
  const [isShowDevice, setIsShowDevice] = useState(false);
  const [isShowFlightRoute, setIsShowFlightRoute] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [scale, setScale] = useState(1);
  const [boxWidth, setBoxWidth] = useState(null);
  const [boxHeight, setBoxHeight] = useState(null);
  const [quarantPositon, setQuarantPositon] = useState(null);
  const [ratioAxis, setRatioAxis] = useState(null);

  const isDisableDisplayDevice = useMemo(() => {
    if (mapEditorContentKiriha.axis.x && mapEditorContentKiriha.axis.y) return false;
    return true;
  }, [mapEditorContentKiriha])

  const angle = useMemo(() => {
    const { originPosition, calibration, axis } = mapEditorContentKiriha;
    if (axis.x !== null && axis.y !== null && zoom) {
      const newAngle = calculateAngle(
        { x: originPosition.left, y: originPosition.top },
        { x: calibration.left, y: calibration.top },
        { x: axis.x, y: axis.y },
        zoom
      );

      return newAngle;
    }
    return 0;
  }, [mapEditorContentKiriha])

  useEffect(() => {
    if (mapEditorContentKiriha) {
      const { originPosition, calibration, axis } = mapEditorContentKiriha;

      setIsShowDevice(!!axis.x && !!axis.y)

      const newShowOrigin = Boolean(originPosition.left && originPosition.top);

      setViewOption({
        ...viewOption,
        origin: newShowOrigin ? newShowOrigin : initViewOption.origin,
        calibration: Boolean(calibration.left && calibration.top),
        axis: axis.x !== null && axis.y !== null,
      })
    }
  }, [])

  useEffect(() => {
    if (tab === "map-advance"){
      convertMarkerDataCSV(markerKirihaData);
    }
  }, [tab, markerKirihaData])

  useEffect(() => {
    if (planRouterSetting.flight.flight_marker) {
      const stationIds = planRouterSetting.flight.flight_marker.filter(item => item.isStation && item.isStation !== "false").map(item => item.isStation);
      setStationIdsSelected(stationIds);
    }
  }, [planRouterSetting.flight.flight_marker])

  useEffect(() => {
    if (chargingStation) {
      const stationInfoSelected = chargingStation.reduce((prev, item) => {
        if (stationIdsSelected.includes(item.stationId)) return [ ...prev, item ];
        return prev;
      }, [])

      setChargingStationsView(stationInfoSelected);
    }
  }, [stationIdsSelected, chargingStation])

  useEffect(() => {
    if (chargingStationsView.length) {
      const stationTypeIds = [...new Set(chargingStationsView.map(item => item.stationTypeId).filter(item => item))];
      getGetStationTypes(stationTypeIds);
    }
  }, [chargingStationsView])

  const getGetStationTypes = async (ids) => {
    const result = [];
    for (const stationTypeId of ids) {
      try {
        await axios.get(Constants.STATION_TYPE_URL + "/" + stationTypeId, getHeader()).then(res => {
          result.push(res.data);
        })
      } catch (error) {
        console.log(error);
      }
    }

    setStationTypes(result);
  }

  const convertMarkerDataCSV = (data) => {
    if (data) {
      const markerResponseClone = cloneDeep(data);
      markerResponseClone.shift();

      const newMarkerData = markerResponseClone.map(item => {
        const id = item.split(",")[0] || null;
        const x = item.split(",")[1] || null;
        const z = item.split(",")[2] || null;

        return { id, x, y: 0, z };
      })

      setMarkerDataCsv(newMarkerData);
    } else {
      setMarkerDataCsv([]);
    }
  }

  // Tab Upload
  const handleClickChangeFile = () => {
    document.getElementById('imageMapEditorUpload').click()
  }

  const switchContentTabEditAdvance = () => {
    const disableAxis = mapEditorImage && (Number(mapEditorContentKiriha.axis.x) === 0 && Number(mapEditorContentKiriha.axis.y) === 0 || !mapEditorContentKiriha.axis.x || !mapEditorContentKiriha.axis.y);
    const disableShowDevice = !mapEditorImage || mapEditorImage && (disableAxis || !viewOption.axis);

    return(
      <>
        <div className='form-control-kiriha'>
          <h3 className='tab-title'>{t('plan.tab_2.kirihaMode.upload.title')}</h3>
          <p className='tab-desc'>{t('plan.tab_2.kirihaMode.upload.desc')}</p>
          <div className="tab-from">
            <div className="mask-tab-form" onClick={() => handleClickChangeFile()}>
              <Form className="d-inline-block hidden">
                <Input
                  id="imageMapEditorUpload"
                  type="file"
                  name="planName"
                  placeholder={accountType === 'robot' ? t('planMap.travel_plan_name') : t('planMap.flight_plan_name')}
                  message={accountType === 'robot' ? t('planMap.Please_enter_the_move_plane_name') : t('planMap.Please_enter_the_flight_plane_name')}
                  accept="image/*,application/pdf"
                  validations={[Validator.required]}
                  onChange={(e) => handleChangeImageFile(e, tab)}
                />
              </Form>
              <span>{t('plan.tab_2.kirihaMode.upload.selected')}</span>
            </div>
          </div>
        </div>

        <div className={'form-control-kiriha' + (mapEditorImage ? "" : " disable")}>
          <h3 className='tab-title'>{t('plan.tab_2.kirihaMode.setting.title')}</h3>
          <p className='tab-desc'>{t('plan.tab_2.kirihaMode.setting.desc')}</p>
          <div className="tab-from form-setting-point">
            <div className='row-control'>
              <span>
                <Images.IconPoint />
                <label>Origin</label>
              </span>

              <label>
                {viewOption.origin ? <Images.IconVisible /> : <Images.IconInVisible />}
                <input className='hidden' name="view-option" type='checkbox' checked={viewOption.origin} onChange={(e) => handleChangeViewOption(e, "origin")}/>
              </label>
            </div>

            <div className='row-control'>
              <span>
                <Images.IconPoint color="#49A7DD" />
                <label>Calibration Point</label>
              </span>

              <label>
                {viewOption.calibration ? <Images.IconVisible /> : <Images.IconInVisible />}
                <input className='hidden' name="view-option" type='checkbox' checked={viewOption.calibration} onChange={(e) => handleChangeViewOption(e, "calibration")}/>
              </label>
            </div>

            <div className={'row-control' + (disableAxis ? ' disable' : "")}>
              <span>
                <Images.IconOxyAxis />
                <label>x-y Axis</label>
              </span>

              <label>
                {viewOption.axis && !disableAxis ? <Images.IconVisible /> : <Images.IconInVisible />}
                <input className='hidden' name="view-option" type='checkbox' checked={viewOption.axis} onChange={(e) => handleChangeViewOption(e, "axis")}/>
              </label>
            </div>
          </div>
        </div>


        <div className={'form-control-kiriha' + (disableShowDevice ? " disable" : "")}>
          <h3 className='tab-title'>{t('plan.tab_2.kirihaMode.confirm.title')}</h3>
          <div className="tab-from form-second-actions" style={{ marginBottom: "8px" }}>
            <div className={'row-control'}>
              <span>
                <Images.IconMarkerPreview />
                <label>Marker and Station</label>
              </span>

              <label>
                {isShowDevice ? <Images.IconVisible /> : <Images.IconInVisible />}
                <input className='hidden' type='checkbox' checked={isShowDevice} onChange={(e) => setIsShowDevice(e.target.checked)}/>
              </label>
            </div>
          </div>
          <button 
            className={'view-route' + (isShowFlightRoute ? " active" : "")}
            onClick={() => setIsShowFlightRoute(!isShowFlightRoute)}
            disabled={isDisableDisplayDevice || !isShowDevice || isShowFlightRoute}
          >
            <Images.IconPlay />
            Display flight Route
          </button>
        </div>
      </>
    )
  }

  const handleChangeViewOption = (e, option) => {
    setViewOption({
      ...viewOption,
      [option]: e.target.checked
    })
  }

  // Handle data
  const onChangeOriginPosition = (newOriginPosition) => {
    handleUpdateMapEditorContentKiriha(update(mapEditorContentKiriha, { originPosition: { $set: newOriginPosition } }));
  }

  const onChangeCalibrationPoint = (newCalibrationPoint) => {
    handleUpdateMapEditorContentKiriha(update(mapEditorContentKiriha, { calibration: { $set: newCalibrationPoint } }));
  }

  const onChangeAxis = (newAxis) => {
    handleUpdateMapEditorContentKiriha(update(mapEditorContentKiriha, { axis: { $set: newAxis } }));
    if (Number(newAxis.x) === 0 && Number(newAxis.y) === 0 || !newAxis.x || !newAxis.y) {
      setViewOption(prev => ({ ...prev, axis: false }));
      setIsShowDevice(false);
    } else {
      setViewOption(prev => ({ ...prev, axis: true }));
      setIsShowDevice(true);
    }
  }

  return (
      <>
        <DndProvider backend={isTablet ? TouchBackend : HTML5Backend}>
          <div className="map-editor">
            <div className="map-zone">
              <div className="content-map-zone">
                {switchContentTabEditAdvance()}
              </div>
              <div className="preview-map-zone">
                <MapContext.Provider
                  value={{
                    plan: plan,
                    planRouterSetting: planRouterSetting,
                    planNo: plan.planNo,
                    onChangeOriginPosition: onChangeOriginPosition,
                    originPosition: mapEditorContentKiriha.originPosition,
                    onChangeCalibrationPoint: onChangeCalibrationPoint,
                    calibration: mapEditorContentKiriha.calibration,
                    axis: mapEditorContentKiriha.axis,
                    onChangeAxis: onChangeAxis,
                    markerKirihaData: markerKirihaData,
                    updateMarkerKirihaData: updateMarkerKirihaData,
                    zoom: zoom,
                    isShowDevice: isShowDevice,
                    isShowFlightRoute: isShowFlightRoute,
                    setIsShowFlightRoute: setIsShowFlightRoute,
                    markerDataCsv: markerDataCsv,
                    chargingStationsView: chargingStationsView,
                    stationTypes: stationTypes,
                    scale: scale,
                    updateScale: setScale,
                    boxWidth: boxWidth,
                    boxHeight: boxHeight,
                    setBoxWidth: setBoxWidth,
                    setBoxHeight: setBoxHeight,
                    quarantPositon: quarantPositon,
                    updateQuarantPositon: setQuarantPositon,
                    angle: angle,
                    ratioAxis: ratioAxis, updateRatioAxis: setRatioAxis,
                    onChangeRouterSt: onChangeRouterSt
                  }}
                >
                  <MapCanvasKirihaMode
                    tab={tab}
                    id={plan.id}
                    url={mapEditorImage}
                    viewOption={viewOption}
                    onChangeZoom={setZoom}
                  />
                </MapContext.Provider>
              </div>
            </div>
          </div>
        </DndProvider>
      </>
  );
};

export default PlanMapKirihaMode;