import React, {useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {useDrop, useDragLayer} from 'react-dnd';
import update from 'immutability-helper';
import {useTranslation} from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { MarkerDraggableKirihaMode } from './markerDraggableKirihaMode';
import AxisSystemView from "./axisSystem";
import * as Constants from "../../../utils/constants";
import { MapContext } from '../../plan/Tab/planMapKirihaMode';
import DeviceViewComponent from "../../plan/KirihaMode/deviceView";
import FlightRouteAnimate from '../../plan/KirihaMode/flightRouteAnimate';
import * as Images from "../../images";

const imageStyle = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'block',
};

const containerAreaStyle = {
  width: '100%',
  height: '100%',
};

const containerStyle = {
  position: 'relative',
  display: 'contents',
  overflow: "hidden"
};

const DISPLAY_TYPE = {
  ORIGIN: "origin",
  CALIBRATION: "calibration"
}

export const MapCanvasKirihaMode = (props) => {
  const { 
    tab, id, url, 
    viewOption, 
    onChangeZoom,
  } = props;

  const {
    originPosition,
    onChangeOriginPosition,
    calibration,
    onChangeCalibrationPoint,
    isShowDevice,
    isShowFlightRoute,
    scale, updateScale,
    setBoxWidth, setBoxHeight,
    zoom
  } = useContext(MapContext);

  const [loading, setLoading] = useState(false);
  const [imageSource, setImageSource] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [displayTypeDrag, setDisplayTypeDrag] = useState(null);
  const [psDrag, setPsDrag] = useState(null);
  const [positionFormSettingCalibration, setPositionFormSettingCalibration] = useState(null);
  const [isMove, setIsMove] = useState(false);
  const [isFitWidth, setIsFitWidth] = useState(false);
  const [isFitHeight, setIsFitHeight] = useState(false);
  const [isPanning, setIsPanning] = useState(false);

  const imgRef = useRef(null);
  const containerRef = useRef(null);
  const actionsRef = useRef(null);
  const transformWrapperRef = useRef(null);

  const border = useMemo(() => {
    return isDragging ? "1px dashed #fc405b" : "1px solid #fff";
  }, [isDragging])

  useEffect(() => {
    if (positionFormSettingCalibration) {
      setPositionFormSettingCalibration(null);
    }
    centerImageView();
  }, [window.innerWidth, window.innerHeight])

  useEffect(() => {
    if (url) {
      setImageSource(url.includes('data:image') ? url : Constants.getResourceUrl() + url);
    }
    setLoading(false);
    imageStyle.width = '';
    imageStyle.height = '';
    containerStyle.width = '';
    containerStyle.height = '';
    containerStyle.display = 'contents';
  }, [url]);

  useEffect(() => {
    let elem = imgRef.current;
    let containerRef = document.getElementById('image-map-view-area-id' + id);
    let zoom = 0;
    if (loading && elem && containerRef) {
      imageStyle.width = '';
      imageStyle.height = '';
      containerStyle.width = '';
      containerStyle.height = '';
      containerStyle.display = 'contents';
      let zoomX = elem.clientWidth / elem.naturalWidth;
      let zoomY = elem.clientHeight / elem.naturalHeight;

      zoom = Math.min(zoomX, zoomY);
      if (zoom !== 0) {
        containerStyle.height = elem.naturalHeight * zoom;
        containerStyle.width = elem.naturalWidth * zoom;
        containerStyle.display = 'block';
        imageStyle.height = elem.naturalHeight * zoom;
        imageStyle.width = elem.naturalWidth * zoom;

        setBoxWidth(elem.naturalWidth * zoom);
        setBoxHeight(elem.naturalHeight * zoom);

        onChangeZoom(zoom);
        centerImageView();
      }
    }
  }, [loading, tab])

  useEffect(() => {
    if (!viewOption.calibration) {
      setPositionFormSettingCalibration(null);
    }
  }, [viewOption])

  useEffect(() => {
    checkFit();
  }, [scale, zoom])

  const checkFit = () => {
    if (actionsRef.current && imgRef.current && tab === "map-advance") {
      const widthWrap = actionsRef.current.parentElement.clientWidth;
      const widthImg = imgRef.current.offsetWidth * scale;
      const heightWrap = actionsRef.current.parentElement.clientHeight;
      const heightImg = imgRef.current.offsetHeight * scale;

      setIsFitWidth(Math.floor(widthWrap) === Math.floor(widthImg));
      setIsFitHeight(Math.floor(heightWrap) === Math.floor(heightImg));
    }
  }

  const moveBox = (displayType, left, top) => {
    if (displayType === DISPLAY_TYPE.ORIGIN) {
      onChangeOriginPosition(update(originPosition, { $merge: { left, top } }));
    }

    if (displayType === DISPLAY_TYPE.CALIBRATION) {
      onChangeCalibrationPoint(update(calibration, { $merge: { left, top } }));
    }
  };

  const [, drop] = useDrop({
    accept: 'marker',
    drop(item, monitor) {
      setDisplayTypeDrag(null);
      const offset = monitor.getSourceClientOffset();
      if (offset && imgRef.current) {
        const dropTarget = imgRef.current.getBoundingClientRect();
        const left = (offset.x) - (dropTarget.left);
        const top = (offset.y) - (dropTarget.top);

        moveBox(item.displayType, left / zoom / scale, top / zoom / scale);
        if (item.displayType === DISPLAY_TYPE.CALIBRATION) {
          setPositionFormSettingCalibration({ top: offset.y, left: offset.x })
        }
      }
      return undefined;
    },
  });

  const imageOnLoad = () => {
    setLoading(true);
  };

  const renderViewOption = useCallback(() => {
    return (
      <React.Fragment>
        {viewOption.origin &&
          <MarkerDraggableKirihaMode
            onUpdateDraggingStatus={setIsDragging}
            onUpdateDisplayTypeDrag={setDisplayTypeDrag}
            onUpdatePsDrag={setPsDrag}
            displayType={DISPLAY_TYPE.ORIGIN} 
          />
        }
        {viewOption.calibration && 
          <MarkerDraggableKirihaMode
            onUpdateDraggingStatus={setIsDragging}
            onUpdateDisplayTypeDrag={setDisplayTypeDrag}
            onUpdatePsDrag={setPsDrag}
            displayType={DISPLAY_TYPE.CALIBRATION}
            positionFormSettingCalibration={positionFormSettingCalibration}
            updatePositionFormSettingCalibration={setPositionFormSettingCalibration}
            onHiddenFormSettingCalibration={() => setPositionFormSettingCalibration(null)}
          />
        }
        {viewOption.axis && tab === "map-advance" && <AxisSystemView />}
      </React.Fragment>
    )
  }, [viewOption, zoom, originPosition, calibration, positionFormSettingCalibration, tab])

  const handleFitHeight = () => {
    setIsMove(true);
    setTimeout(() => {
      if (transformWrapperRef.current) {
        const elem = imgRef.current;
        const containerRef = document.getElementById('image-map-view-area-id' + id);
        const wrapperHeight = actionsRef.current.parentElement.clientHeight;
        const { setTransform } = transformWrapperRef.current;

        let newPositionX = 0;

        if (elem && containerRef) {
          const imageScale = wrapperHeight / elem.clientHeight;
          const newWidthImg = elem.clientWidth * imageScale;
          const newHeightImg = wrapperHeight;
          let zoomX = newWidthImg / elem.naturalWidth;
          let zoomY = newHeightImg / elem.naturalHeight;
    
          const newZoom = Math.min(zoomX, zoomY);
          if (newZoom !== 0) {
            containerStyle.height = wrapperHeight;
            containerStyle.width = elem.naturalWidth * newZoom;
            containerStyle.display = 'block';
            imageStyle.height = wrapperHeight;
            imageStyle.width = elem.naturalWidth * newZoom;

            newPositionX = (actionsRef.current.parentElement.clientWidth - imageStyle.width) / 2;
    
            setBoxWidth(elem.naturalWidth * newZoom);
            setBoxHeight(wrapperHeight);
    
            onChangeZoom(newZoom);
          }
        }

        updateScale(1);
        setTransform(newPositionX, 0, 1);
      }
    }, 10);
  }

  const handleFitWidth = () => {
    setIsMove(true);
    setTimeout(() => {
      if (transformWrapperRef.current) {
        const elem = imgRef.current;
        const containerRef = document.getElementById('image-map-view-area-id' + id);
        const wrapperWidth = actionsRef.current.parentElement.clientWidth;
        const { setTransform } = transformWrapperRef.current;
  
        let newPositionY = 0;

        if (elem && containerRef) {
          const imageScale = wrapperWidth / elem.clientWidth;
          const newWidthImg = wrapperWidth;
          const newHeightImg = elem.clientHeight * imageScale;
          let zoomX = newWidthImg / elem.naturalWidth;
          let zoomY = newHeightImg / elem.naturalHeight;
    
          const newZoom = Math.min(zoomX, zoomY);
          if (newZoom !== 0) {
            containerStyle.height = elem.naturalHeight * newZoom;
            containerStyle.width = newWidthImg;
            containerStyle.display = 'block';
            imageStyle.height = elem.naturalHeight * newZoom;
            imageStyle.width = newWidthImg;

            newPositionY = (actionsRef.current.parentElement.clientHeight - imageStyle.height) / 2;
    
            setBoxWidth(newWidthImg);
            setBoxHeight(elem.naturalHeight * newZoom);
    
            onChangeZoom(newZoom);
          }
        }
  
        updateScale(1);
        setTransform(0, newPositionY, 1);
      }
    }, 10);
  }

  const centerImageView = () => {
    if (transformWrapperRef.current) {
      const { centerView } = transformWrapperRef.current;
      centerView();
    }
  }

  const handleZoom = (e, status) => {
    e.target.disabled = true;
    if (status) {
      transformWrapperRef.current.zoomIn(1);
    } else {
      transformWrapperRef.current.zoomOut(1);
    }

    setTimeout(() => {
      const { state } = transformWrapperRef.current.instance.getContext();
  
      updateScale(Math.round(state.previousScale));
      e.target.disabled = false;
    }, 300);
  }

  return (
    <>
      {
        url ?
          <TransformWrapper
            ref={transformWrapperRef}
            disabled={!isMove}
            onPanningStart={() => setIsPanning(true)}
            onPanningStop={() => setIsPanning(false)}
            smooth={false}
            wheel={{disabled: true}}
            doubleClick={{disabled: true}}
          >
            {({ zoomIn, zoomOut, setTransform, ...rest }) => (
              <React.Fragment>
                <div className='img-actions' ref={actionsRef}>
                  <div className='action-img'>
                    <button onClick={(e) => handleZoom(e, true)}><Images.IconPlus /></button>  
                    <button onClick={(e) => handleZoom(e, false)}><Images.IconMinus /></button>
                    <button className={isFitHeight ? "active" : undefined} onClick={handleFitHeight}>
                      <Images.IconFitHeight color={isFitHeight ? "#fff" : undefined} />
                    </button>
                    <button className={isFitWidth ? "active" : undefined } onClick={handleFitWidth}>
                      <Images.IconFitWidth color={isFitWidth ? "#fff" : undefined} />
                    </button>
                  </div>
                  <button className={`btn-move ${isMove ? "active" : ""} `} onClick={() => setIsMove(!isMove)}>
                    <Images.IconMove color={isMove ? "#fff" : undefined} />
                  </button>
                </div>

                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: '100%',
                    cursor: isMove ? isPanning ? "grabbing" : "grab": "default"
                  }}
                >
                  <div id={"image-map-view-area-id"+ id} style={{...containerAreaStyle}} className='image-map-view-area' ref={containerRef}>
                    {!loading &&
                      <div className='loading'>
                        <img src={require('../../../assets/images/loading.gif')} alt="loading"/>
                      </div>
                    }
                    
                    <div 
                      style={{...containerStyle }} 
                      className={loading ? "image-map-view" : "invisible"} 
                      ref={drop}
                    >
                      <img 
                        className="image-map-image" 
                        src={imageSource}
                        style={{...imageStyle, border}}
                        onLoad={imageOnLoad} 
                        ref={imgRef}
                        id={"map-image-loaded" + id}
                        alt=""
                      />

                      {renderViewOption()}
                      {isShowDevice && <DeviceViewComponent isMove={isMove} />}
                      {isShowFlightRoute && <FlightRouteAnimate />}
                    </div>
                  </div>
                </TransformComponent>

                <CustomDragLayer displayTypeDrag={displayTypeDrag} psDrag={psDrag} />
              </React.Fragment>
            )}
          </TransformWrapper>
        : 
        <p className="no-img center notice">No image</p>
      }
    </>
  );
}

const CustomDragLayer = ({ displayTypeDrag, psDrag }) => {
  const { isDragging, currentOffset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getClientOffset(),
  }));

  if (!isDragging || !currentOffset) {
    return null;
  }

  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    top: currentOffset.y - Number(psDrag.y),
    left: currentOffset.x - Number(psDrag.x),
    transform: `translate(-50%, -50%)`,
  };

  return (
    <div style={layerStyles}>
      <Images.IconPoint color={displayTypeDrag === "calibration" ? "#49A7DD" : ""} />
    </div>
  );
};