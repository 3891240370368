import React, {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import MenuAdmin from "../../components/menu-admin";
import {confirmAlert} from 'react-confirm-alert';
import * as Images from "../../components/images";
import Form from "react-validation/build/form";
import * as Constants from "../../utils/constants";
import {doDelete, doGet, getHeader} from "../../services/api.service";
import cloneDeep from 'lodash/cloneDeep';
import {useTranslation} from 'react-i18next';
import LanguageSwitch from "../../components/languageSwitch";
import {NotificationManager} from 'react-notifications';
import pdfjs from "pdfjs-dist/legacy/build/pdf.js"
import axios from "axios";
import MenuSettingAdmin from "../../components/menu/menu-setting-admin";

const initialDroneType = {
  name: "",
  flightTime: "",
  flightDistance: "",
  maxSpeed: "",
  imageName: "",
};

const tableHeader = [
  'setting.droneType.table.header_1',
  'setting.droneType.table.header_2',
  'setting.droneType.table.header_3',
  'setting.droneType.table.header_4',
  'setting.droneType.table.header_5',
  'setting.droneType.table.header_6',
  ''
];

export default function DroneType(props) {
  const { t } = useTranslation();
  const [droneTypes, setDroneTypes] = useState([]);
  const [canSaveInEdit, setCanSaveInEdit] = useState(false);
  const [droneTypeImageFile, setDroneTypeImageFile] = useState(null);
  
  const [isShowModal, setIsShowModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [droneType, setDroneType] = useState(initialDroneType);

  useEffect(() => {
    getListDroneTypes();
  }, []);
  
  // get data
  function getListDroneTypes() {
    doGet(Constants.DRONE_TYPE_URL, null,
      (res) => {
        setDroneTypes(res.data);
      },
      (error) => console.log(error)
    )
  }

  // action
  function handleClose() {
    setEditing(false);
    setIsShowModal(false);
    setDroneTypeImageFile(null);
    setDroneType(initialDroneType);
    setCanSaveInEdit(false);
  }

  function handleShowEdit(item) {
    setEditing(true);
    setIsShowModal(true);
    setDroneType(cloneDeep(item))
  }

  // handle change form
  function handleEditChange(event) {
    const {name, value} = event.target;
    setDroneType({ ...droneType, [name]: value });
    setCanSaveInEdit(true);
  }

  const changeDroneTypeImg = (e) => {
    e.preventDefault();
    document.getElementById('droneTypeImg').click()
  }

  const handleChangeImageFile = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].type !== "application/pdf") {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        setDroneTypeImageFile(e.target.files[0]);
        setDroneType({
          ...droneType,
          imageName: e.target.files[0].name
        });
        setCanSaveInEdit(true);
      } else {
        let file = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function () {
          let pdfData = new Uint8Array(this.result);
          // Using DocumentInitParameters object to load binary data.
          let loadingTask = pdfjs.getDocument({ data: pdfData });
          loadingTask.promise.then(
            function (pdf) {
              console.log("PDF loaded");

              // Fetch the first page
              let pageNumber = 1;
              pdf.getPage(pageNumber).then(function (page) {
                console.log("Page loaded");

                let scale = 1.0;
                let viewport = page.getViewport({ scale: scale });

                // Prepare canvas using PDF page dimensions
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                let renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                let renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                  let url = canvas.toDataURL();
                  // Split the base64 string in data and contentType
                  let block = url.split(";");
                  // Get the content type
                  let contentType = block[0].split(":")[1]; // In this case "image/gif"
                  // get the real base64 content of the file
                  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

                  // Convert to blob
                  let blob = b64toBlob(realData, contentType);
                  setDroneTypeImageFile(blob);
                  setDroneType({
                    ...droneType,
                    imageName: e.target.files[0].name
                  });
                  setCanSaveInEdit(true);
                });
              });
            },
            function (reason) {
              // PDF loading error
              console.error(reason);
            }
          );
        };
        fileReader.readAsArrayBuffer(file);
      }
    }
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // handle submit data
  function handleSubmit(event) {
    event.preventDefault();
    setCanSaveInEdit(false);

    const method = editing ? "put" : "post";
    const url = editing ? Constants.DRONE_TYPE_UD_URL + droneType.id : Constants.DRONE_TYPE_URL;

    var formData = new FormData();
    formData.append('name', droneType.name);
    formData.append('flightTime', droneType.flightTime);
    formData.append('flightDistance', droneType.flightDistance);
    formData.append('maxSpeed', droneType.maxSpeed);
    if(droneTypeImageFile) {
      formData.append('file', droneTypeImageFile);
    }

    axios[method](url, formData, getHeader('multipart/form-data')).then(() => {
      NotificationManager.success(t('post.success'), '', 2000);
      handleClose();
      getListDroneTypes();
    }).catch((err) => {
      const { err_msg } = err.response.data;
      NotificationManager.error(err_msg, '', 2000);
      setCanSaveInEdit(true);
    })
  }

  function handleDelete(event, id) {
    event.preventDefault();

    confirmAlert({
      title: t('setting.do_you_want_delete_it'),
      message: '',
      buttons: [
        {
          label: t('common.ok'),
          onClick: () => {
            let request = Constants.DRONE_TYPE_UD_URL + id;
            doDelete(request, () => {
              handleClose();
              getListDroneTypes();
              NotificationManager.success(t("dialog_delete.confirm_success"), "", 2000);
            }, () => {
              NotificationManager.error(t("dialog_delete.confirm_error"), "", 2000);
            })
          }
        },
        {
          label: t('dialog_delete.option_no')
        }
      ]
    });
  }

  // render table
  function renderItemHeaderTable(data, t) {
    return data.map((item, index) => {
      return <div className="clmn" key={index}>{t(item)}</div>;
    });
  }

  function renderItemTable(data) {
    return data.map((item, index) => {
      return (
        <div className="list-item" key={index}>
          <div className="clmn">{index + 1}</div>
          <div className="clmn">{item.name}</div>
          <div className="clmn">{item.flightTime}</div>
          <div className="clmn">{item.flightDistance}</div>
          <div className="clmn">{item.maxSpeed}</div>
          <div className="drone_img clmn">
            <img src={Constants.getResourceUrl() + item.imagePath} alt="" />
          </div>

          <div className="actions d-flex clmn">
            <button className="btn-small edit position-relative" onClick={() => handleShowEdit(item)}>
              <div className="fix-icon-center-ios">
                <Images.Icons name="IconPen"/>
              </div>
            </button>
            <button className="btn-small remove position-relative" onClick={(e) => handleDelete(e, item.id)}>
              <div className="fix-icon-center-ios">
                <Images.Icons name="IconTrash"/>
              </div>
            </button>
          </div>
        </div>
      );
    });
  }
 
  // Main render
  return (
    <>
      <div className="dps-menu ">
        <MenuAdmin currentRouter={props.location.pathname}/>
      </div>
      <div className="dps-content admin-page setting-page">
        <div className="dps-content-area">
          <div className="dps-title main-title">
            <h1>{t('avatar.main_title')}</h1>
            <LanguageSwitch/>
          </div>
          <div className="dps-main-content">
            <div className="dps-main-content-area">
              <MenuSettingAdmin currentRouter={props.location.pathname}></MenuSettingAdmin>
              <div className="dps-tabs-content admin-setting d-flex flex-column position-relative bg-light">
                <button className="add-new" onClick={() => setIsShowModal(true)}>+ New Drone Type</button>
                <div className="dps-list-f table-setting">
                  <div className="header">
                    {renderItemHeaderTable(tableHeader, t)}
                  </div>
                  <div className="body">
                    {renderItemTable(droneTypes)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="edit-form setting-form" dialogClassName="setting-form-dialog" show={isShowModal} centered onHide={handleClose}>
        <Modal.Body>
          <button className="btn-close" onClick={handleClose}></button>
          <Form className="edit-type" onSubmit={handleSubmit}>
            <div className="item">
              <label>{t('setting.droneType.table.header_2')}</label>
              <input
                type="text"
                className="grow-1"
                name="name"
                placeholder={t('setting.droneType.table.header_2')}
                value={droneType.name}
                onChange={handleEditChange}
                disabled={editing}
              />
            </div>
            <div className="item">
              <label>{t('setting.droneType.table.header_3')}</label>
              <input
                type="number"
                name="flightTime"
                min={0}
                step="any"
                placeholder={t('setting.droneType.table.header_3')}
                value={droneType.flightTime}
                onChange={handleEditChange}
              />
            </div>
            <div className="item">
              <label>{t('setting.droneType.table.header_4')}</label>
              <input
                type="number"
                name="flightDistance"
                min={0}
                step="any"
                placeholder={t('setting.droneType.table.header_4')}
                value={droneType.flightDistance}
                onChange={handleEditChange}
              />
            </div>
            <div className="item">
              <label>{t('setting.droneType.table.header_5')}</label>
              <input
                type="number"
                name="maxSpeed"
                min={0}
                step="any"
                placeholder={t("setting.droneType.table.header_5")}
                value={droneType.maxSpeed}
                onChange={handleEditChange}
              />
            </div>
            <div className="item">
              <label>{t('setting.droneType.table.header_6')}</label>
              <input
                id="droneTypeImg"
                type="file"
                className="hidden"
                name="file"
                onChange={handleChangeImageFile}
              />
              <div className="mash_input grow-1">
                {droneType.imageName ? droneType.imageName : t('setting.droneType.file_select')}
              </div>
              <button type="button" className="icon_folder" onClick={(e) => changeDroneTypeImg(e)}>
                <Images.IconFolder />
              </button>
            </div>
            <div className="actions">
              {editing &&
                <button 
                  className="btn-delete"
                  onClick={(e) => handleDelete(e, droneType.id)}
                >
                  <Images.IconTrash />
                </button>
              }
              <button type="submit" className="btn-save position-relative" disabled={!canSaveInEdit}>
                OK
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}