import { useGridCellEditor } from '@ag-grid-community/react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';

export default memo((props) => {
  const { 
    params: {
      value, 
      onValueChange, 
      eventKey, 
      colDef: { 
        max, min, 
        cellDataType,
        maxLength
      } 
    },
    onChangeIsInValid,
    isCheckOnlyData,
    onChangeIsCheckOnly,
    isNegativeData
  } = props;

  const refInput = useRef(null);
  
  useEffect(() => {
    let startValue;
    let highlightAllOnFocus = true;

    if (eventKey === KEY_BACKSPACE) {
      startValue = '';
    } else if (eventKey && eventKey.length === 1) {
      // if a letter was pressed, we start with the letter
      startValue = eventKey;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = value;
      if (eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }
    if (startValue == null) {
      startValue = '';
    }

    updateValue(startValue);

    // get ref from React component
    const eInput = refInput.current;
    eInput.focus();
    if (highlightAllOnFocus) {
      eInput.select();
    }
  }, []);

  const updateValue = (val) => {
    let isInValid = false, isCheckOnly = false;

    if (cellDataType === "number") {
      const newValue = validNumberic(val);
      isInValid = newValue !== null && (newValue < min || newValue > max);
      
      onValueChange(newValue);
    }

    if (cellDataType === "string") {
      isInValid = val && val.length > maxLength;
      onValueChange(val);
    }

    if (isCheckOnlyData) {
      isCheckOnly = validOnlyId(val);
    }
    
    if (isInValid || isCheckOnly) {
      refInput.current.parentElement.classList.add("cell-invalid");
    } else {
      refInput.current.parentElement.classList.remove("cell-invalid");
    }

    if (isCheckOnly) {
      props.params.colDef["isCheckOnly"] = true;
    } else {
      delete props.params.colDef["isCheckOnly"];
    }

    onChangeIsInValid(isInValid);
    onChangeIsCheckOnly && onChangeIsCheckOnly(isCheckOnly);
  };

  const validNumberic = (value) => {
    if (!value && value !== 0) return null;
    const currentVal = parseFloat(value);
    return isNaN(currentVal) ? null : currentVal;
  }

  const validOnlyId = (val) => {
    const totalRow = props.params.api.getDisplayedRowCount();
    const { colDef: { field }, rowIndex } = props.params;
    const allRowsDataNotCurrent = [];

    for (let i = 0; i < totalRow; i++) {
      if (i !== rowIndex) {
        let rowNode = props.params.api.getDisplayedRowAtIndex(i);
        allRowsDataNotCurrent.push(rowNode.data[field]);
      }
    }

    if (allRowsDataNotCurrent.includes(parseInt(val))) return true;
    return false;
  }
  
  const isCancelBeforeStart = useCallback(() => {
    return !!eventKey && eventKey.length === 1 && '1234567890'.indexOf(eventKey) < 0;
  }, [eventKey]);

  useGridCellEditor({
    isCancelBeforeStart,
  });

  return (
    <div className='custom-cell-input'>
      <input
        type={cellDataType || "number"}
        ref={refInput}
        value={value === null ? '' : value}
        onChange={(event) => updateValue(event.target.value)}
        onKeyDown={e => {
          if (cellDataType === "number") {
            if (e.key.toLocaleLowerCase() === 'e' || (!isNegativeData && e.key.toLocaleLowerCase() === '-')) e.preventDefault();
          }
        }}
      />
    </div>
  );
});
