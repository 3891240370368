import { memo, useEffect, useMemo, useState } from "react";
import momentTimezone from 'moment-timezone';
import { AnimatePresence, Reorder } from "framer-motion";
import update from "immutability-helper";
import cloneDeep from "lodash/cloneDeep";
import { confirmAlert } from "react-confirm-alert";

import * as Images from "../../images";
import * as Constants from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { generateUUIDv4, timeConverter } from "../../../utils/utils";
import KirihaEditTableComponent from '../KirihaMode/KirihaTable/kirihaEditTable';
import FlightMarkerKiriha from "../FlightMarker/flightMarkerKiriha";
import FlightMarker from "../FlightMarker/flightMarker";
import { doGet } from "../../../services/api.service";

const faceKirahaMode = "face";
const markerKirihaMode = "marker";
export const kirihaLabel = "kiriha";

const PlanFlightRouteSetting = (props) => {
  const KIRIHA_MODE = JSON.parse(localStorage.getItem("user")).kirihaMode;
  
  const {
    mainData,
    plan, updatePlan,
    chargingStation,
    handleInputChange,
    errorMessage, setErrorMessage,
    errorElement, setErrorElement,
    isChange, onChangeRouterSt,
    faceKirihaData, updateFaceKirihaData,
    markerKirihaData, updateMarkerKirihaData,
    updateStatusChangeKirihaFlag
  } = props;

  const [markerData, setMarkerData] = useState([]);

  // Kiriha
  const [faceCsvUploadedInfo, setFaceCsvUploadedInfo] = useState(null);
  const [isOpenEditFaceKiriha, setIsOpenEditFaceKiriha] = useState(false);
  const [markerCsvUploadedInfo, setMarkerCsvUploadedInfo] = useState(null);
  const [isOpenEditMarkerKiriha, setIsOpenEditMarkerKiriha] = useState(false);
  const [markerIdsOption, setMarkerIdsOption] = useState([]);

  const { t } = useTranslation();

  const numberOfMarker = useMemo(() => {
    const realFlightPlan = [];
    plan.flight.flight_marker.forEach(item => {
      if (item.id !== kirihaLabel) realFlightPlan.push(item.id);
    })

    return [...new Set(realFlightPlan)].length
  }, [plan]);
  const isKirihaMode = useMemo(() => { return plan.kirihaMode }, [plan]);
  const isDisableOptionKiriha = useMemo(() => {
    if (plan.flight.flight_marker) {
      return plan.flight.flight_marker.some(mk => mk.kiriha);
    }
  }, [plan.flight.flight_marker])

  useEffect(() => {
    if (mainData) {
      const {
        faceCsvFile,
        markerCsvFile,
      } = mainData;
  
      setFaceCsvUploadedInfo(faceCsvFile);
      setMarkerCsvUploadedInfo(markerCsvFile);
    }
  }, [mainData])

  useEffect(() => {
    setMarkerData(plan.flight.flight_marker);
  }, [plan.flight.flight_marker])

  useEffect(() => {
    if(!isOpenEditFaceKiriha && !isOpenEditMarkerKiriha) {
      const notiWrap = document.querySelector(".notification-container > div");
      if(notiWrap) {
        notiWrap.classList.remove("noty-plan")
      }
    }
  }, [isOpenEditFaceKiriha, isOpenEditMarkerKiriha])

  useEffect(() => {
    if (isChange) {
      const indexMarkerKiriha = markerData.findIndex(mk => mk.id === kirihaLabel);

      markerData.forEach((marker, index) => {
        markerData[index].kiriha = false;
      })

      if (indexMarkerKiriha !== -1 && indexMarkerKiriha > 0) {
        markerData[indexMarkerKiriha - 1].kiriha = true;
      }

      updatePlan( update(plan, { flight: { flight_marker: { $set: markerData } } }) );
    }
  }, [markerData]);

  useEffect(() => {
    if (markerKirihaData.length) {
      const markerKirihaDataClone = cloneDeep(markerKirihaData);
      markerKirihaDataClone.shift();
      const markerIds = [ { value: kirihaLabel, title: kirihaLabel } ];

      markerKirihaDataClone.forEach(item => {
        if (item.split(",")[0] && !isNaN(Number(item.split(",")[0]))) {
          const newOption = {
            value: Number(item.split(",")[0]),
            title: "Marker " + Number(item.split(",")[0]),
          }
          markerIds.push(newOption);
        }
      })

      setMarkerIdsOption(markerIds);
    }
  }, [isKirihaMode, markerKirihaData])

  const updateCsvFile = async (file, mode) => {
    switch (mode) {
      case faceKirahaMode:
        updateFaceKirihaData(file);
        onChangeRouterSt();
        updateStatusChangeKirihaFlag(prev => ({
          ...prev, face: true
        }));
        return true;
      case markerKirihaMode:
        updateMarkerKirihaData(file);
        onChangeRouterSt();
        updateStatusChangeKirihaFlag(prev => ({
          ...prev, marker: true
        }));
        return true;

      default: break;
    }
  }

  const handleChangeKirihaMode = (e) => {
    const { checked } = e.target;
    confirmAlert({
      title: t("plan.dialog_kiriha"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            const newMarkerData = cloneDeep(plan.flight.flight_marker).map(item => {
              if (!isKirihaMode) {
                const markerConvert = { ...item, kiriha: false }
                if (markerIdsOption.every(marker => marker.value !== item.id)) return { ...markerConvert, id: null };
                return markerConvert;
              }
              return item;
            });
            updatePlan( update(plan, { 
              kirihaMode : { $set: checked }, 
              flight: { flight_marker: { $set: newMarkerData } }
            }));
            onChangeRouterSt();
          },
        },
        {
          label: t("dialog_delete.option_no"),
        },
      ],
    });
  }

  const handleSortableMarker = (data) => {
    if (data[0].id !== kirihaLabel) {
      setMarkerData(data);
      onChangeRouterSt();
    } else {
      setMarkerData(cloneDeep(markerData));
    }
  };

  const addMoreMarker = (markerContentTobeCopied, index) => {
    let newMarker = cloneDeep(markerContentTobeCopied);
    newMarker.id = KIRIHA_MODE ? null : markerContentTobeCopied.id + 1;
    newMarker.kiriha = false;
    newMarker.markerId = generateUUIDv4();

    const flightMarker = [...plan.flight.flight_marker];
    flightMarker.splice(index, 0, newMarker);

    setMarkerData(flightMarker);
    onChangeRouterSt();
  };

  const updateMarker = (marker, position) => {
    setErrorMessage("");
    setErrorElement("");
    let newMarker = update(markerData, { [position]: { $set: marker } });
    setMarkerData(newMarker);
    onChangeRouterSt();
  };

  const deleteMarker = (index) => {
    let markers = [...plan.flight.flight_marker];
    if (index === 0 && markers[index + 1] && markers[index + 1].id === kirihaLabel) {
      markers.splice(index, 2);
    } else {
      markers.splice(index, 1);
    }
    setMarkerData(markers);
    onChangeRouterSt();
  };

  // Render Component
  const renderFormCsv = () => {
    const dataFaceCsvDownload = faceKirihaData.map(item => item.split(','));
    const dataMarkerCsvDownload = markerKirihaData.map(item => item.split(','));

    return (
      <>
        <div className="form-csv">
          <div className="form-item form-vertical form-kiriha_mode">
            <span>Kiriha Mode</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={Boolean(isKirihaMode)}
                onChange={handleChangeKirihaMode}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className={"form-item form-vertical form-capture_distance" + (isKirihaMode ? "" : " disable-kr")}>
            <span>{t("plan.capture_distance")}</span>
            <label>
              <input
                type="number"
                name="capture_distance"
                onChange={handleInputChange}
                min="0"
                value={plan.captureDistance}
              />
            </label>
          </div>

          <div className={"form-item form-vertical form-face_csv" + (isKirihaMode ? "" : " disable-kr")}>
            <button onClick={() => setIsOpenEditFaceKiriha(true)}>
              <Images.IconKirihaIcon color="currentColor" />
              Face data
            </button>
            {faceCsvUploadedInfo && 
              <span>updated at <br /> {timeConverter(momentTimezone(faceCsvUploadedInfo.updatedAt).tz("Asia/Tokyo").unix(), t)}</span>
            }
          </div>

          <div className={"form-item form-vertical form-marker_csv" + (isKirihaMode ? "" : " disable-kr")}>
            <button onClick={() => setIsOpenEditMarkerKiriha(true)}>
              <Images.IconScan color="currentColor" />
              Marker Data
            </button>
            {markerCsvUploadedInfo &&
              <span>updated at <br /> {timeConverter(momentTimezone(markerCsvUploadedInfo.updatedAt).tz("Asia/Tokyo").unix(), t)}</span>
            }
          </div>
        </div>

        {isOpenEditFaceKiriha &&
          <KirihaEditTableComponent
            isOpenEditKiriha={isOpenEditFaceKiriha} 
            setIsOpenEditKiriha={setIsOpenEditFaceKiriha} 
            kirihaData={faceKirihaData}
            handleUpdate={(newCsv, mode) => updateCsvFile(newCsv, mode)}
            csvUploadedInfo={faceCsvUploadedInfo}
            mode={faceKirahaMode}
            dataCsv={dataFaceCsvDownload}
          />
        }
        {isOpenEditMarkerKiriha &&
          <KirihaEditTableComponent 
            isOpenEditKiriha={isOpenEditMarkerKiriha} 
            setIsOpenEditKiriha={setIsOpenEditMarkerKiriha} 
            kirihaData={markerKirihaData}
            handleUpdate={(newCsv, mode) => updateCsvFile(newCsv, mode)}
            csvUploadedInfo={markerCsvUploadedInfo}
            mode={markerKirihaMode}
            dataCsv={dataMarkerCsvDownload}
          />
        }
      </>
    )
  }

  return (
    <>
      {KIRIHA_MODE && renderFormCsv()}
      <div className="form-marker">
        <div className="marker_total">
          <span>
            {t("plan.drone.number_of_markers")}：{numberOfMarker}
          </span>
        </div>
        <Reorder.Group
          values={markerData}
          onReorder={handleSortableMarker}
          className="marker-more"
        >
          <AnimatePresence>
            {markerData.map((marker, index) => {
              if (KIRIHA_MODE && isKirihaMode) {
                return (
                  <FlightMarkerKiriha
                    marker={marker}
                    position={index}
                    chargingStation={chargingStation}
                    key={marker.markerId}
                    updateMarker={updateMarker}
                    addMoreMarker={addMoreMarker}
                    deleteMarker={() => deleteMarker(index)}
                    errorElement={errorElement}
                    errorMessage={errorMessage}
                    markerIdsOption={markerIdsOption}
                    isDisableOptionKiriha={isDisableOptionKiriha}
                  />
                )
              }

              return (
                <FlightMarker
                  marker={marker}
                  position={index}
                  chargingStation={chargingStation}
                  key={marker.markerId}
                  updateMarker={updateMarker}
                  addMoreMarker={addMoreMarker}
                  deleteMarker={() => deleteMarker(index)}
                  errorElement={errorElement}
                  errorMessage={errorMessage}
                />
              )
            })}
          </AnimatePresence>
        </Reorder.Group>
      </div>
    </>
  )
}

export default memo(PlanFlightRouteSetting);