import React, {Component} from "react";
import MenuAdmin from "../../components/menu-admin";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import * as Validator from "../../utils/validator";
import * as Constants from "../../utils/constants";
import {NotificationManager} from 'react-notifications';
import axios from "axios";
import LanguageSwitch from "../../components/languageSwitch";
import {withTranslation} from "react-i18next";
import { getHeader } from "../../services/api.service";
import MenuSettingAdmin from "../../components/menu/menu-setting-admin";

class Icon extends Component {
  constructor(props) {
    super(props);

    // Init component state
    this.state = {
      icons: []
    };
    this.refBtn = React.createRef()

    this.getIconInformation();
    this.handleSave.bind(this);
  }

  // Handle input change then set to state
  handleChange(field, e) {
    let updateState = this.state;
    updateState[field] = e.target.value;
    this.setState({updateState});
  }

  handleSave(e, t) {
    e.preventDefault();
    let header = getHeader('multipart/form-data');
    let index = 0;
    let item = this.state.icons[index];
    if (item && item.file) {

    } else {
      index++;
      item = this.state.icons[index];
    }
    if (item && item.file) {
      var formData = new FormData();

      for (var key in item) {
        formData.append(key, item[key]);
      }
      axios.post(Constants.MASTER_DATA_ICON_URL, formData, header)
      .then(response => {
        index++;
        item = this.state.icons[index];
        if (item && item.file) {
          var formData = new FormData();

          for (var key in item) {
            formData.append(key, item[key]);
          }

          axios.post(Constants.MASTER_DATA_ICON_URL, formData, header)
          .then(response => {
            NotificationManager.success(t('avatar.notifi_success'), '', 2000);

          }).catch(function (error) {
            NotificationManager.error(t('avatar.notifi_error2'), '', 2000);
          });
        } else {
          NotificationManager.success(t('avatar.notifi_success'), '', 2000);
        }

      }).catch(function (error) {
        NotificationManager.error(t('avatar.notifi_error2'), '', 2000);
      });
    }

  }

  getIconInformation() {
    axios.get(Constants.MASTER_DATA_URL + 'icon', getHeader())
    .then(res => {
      let droneIcon = {
        value: '',
        key: 'DRONE_ICON',
        description: 'ドローンアイコン',
      }
      if (!res.data || res.data.length === 0) {
        res.data = [];
        res.data.push(droneIcon);
      } else {
        let existedDroneIcon = res.data.filter(
            icon => icon.key === 'DRONE_ICON');
        if (!existedDroneIcon || existedDroneIcon.length === 0) {
          res.data.push(droneIcon);
        }
      }
      const removeRobot = [...res.data.filter(x => x.key !== "ROBOT_ICON")];
      this.setState({'icons': removeRobot});
    });
  }

  handleChangeFile(field, index, e) {
    let icons = this.state.icons;
    icons[index]['file'] = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(icons[index]['file']);
    reader.onloadend = function (e) {
      icons[index][field] = reader.result;
      this.setState({icons: icons});
    }.bind(this);
    this.refBtn.current.click()
  }

  fileUploadButton(index) {
    document.getElementById('fileUpload' + index).click();
  }

  componentDidMount() {
    // this.getIconInformation()
  }

  //Render Ui for screen
  render() {
    const {t, i18n} = this.props;
    return (
        <>
          <div className="dps-menu ">
            <MenuAdmin currentRouter={this.props.location.pathname}/>
          </div>
          <div className="dps-content admin-page">
            <div className="dps-content-area">
              <div className="dps-title main-title">
                <h1>{t('avatar.main_title')}</h1>
                <LanguageSwitch/>
              </div>
              <div className="dps-main-content">
                <div className="dps-main-content-area">
                  <MenuSettingAdmin currentRouter={this.props.location.pathname}></MenuSettingAdmin>
                  <div className="dps-tabs-content admin-setting">
                    <Form onSubmit={(e) => this.handleSave(e, t)} ref={c => {
                      this.form = c
                    }} className="form-icon">
                      <div className="list-icon">
                        {
                          this.state.icons.map((icon, index) => {
                            return (
                                <div className="item" key={index}>
                                  <p>{t('setting.icon.default_icon')}</p>
                                  <div className="preview-image"
                                       onClick={() => this.fileUploadButton(
                                           index)} key={index}>
                                    {icon.value !== '' ? (icon.value.includes(
                                        'data:image') ? <img alt="back"
                                                             src={icon.value}/>
                                        : <img alt="back"
                                               src={Constants.getResourceUrl()
                                                   + icon.value}/>) : ''}
                                    {icon.value === '' & icon.key
                                    === "DRONE_ICON" ? <img alt="back"
                                                            src={require(
                                                                '../../assets/images/flight.png')}/>
                                        : ''}
                                    <p>{t('setting.icon.upload_img')}</p>
                                  </div>
                                  <Input
                                      id={"fileUpload" + index}
                                      type="file"
                                      className="dps-input "
                                      name="flightPlanName"
                                      placeholder={t("plan.drone.plan_name")}
                                      accept="image/*"
                                      validations={[Validator.required]}
                                      onChange={this.handleChangeFile.bind(this,
                                          "value", index)}
                                  />
                                </div>
                            );
                          })
                        }
                      </div>
                      <button className="dps-button hidden" ref={this.refBtn}>
                        <span>{t('avatar.button_update')}</span>
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

export default withTranslation("dps")(Icon);